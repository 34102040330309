import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/jaws-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1975 killer shark movie, Jaws"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 11</h1>
                    <h2>Jaws</h2>
                    <h3>July 4, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/jaws">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:03.612)
                        You&#39;re listening to the Brothers Grimm Podcast, presented by the Cinema Suicide Film Society on Substack. I&#39;m Brian White, editor in grief of Cinema Suicide. I&#39;m joined by my cohost and actual brother, Dave White. Dave, how you doing? Hey. Yep. So.</p>
                        <p>Dave! (00:15.189)
                        Hey! Y&#39;all know me, know how I make a livin&#39;.</p>
                        <p>Bryan! (00:22.064)
                        So we practically grew up in neighborhood video stores and the steady diet of utter garbage that these shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies and Dave. However, this is not a trashy movie at all. Nope, nope. This is a bonus episode, you know? There are probably, there are two movies that I watch annually, kinda on a schedule. One of them is Halloween, obviously on Halloween.</p>
                        <p>The other is this one. On July 4th, I watch Jaws. Because, like, there&#39;s a bunch of July 4th horror movies. But the other ones? Yes! I can think of two. I know what you did last summer. And Uncle Sam!</p>
                        <p>Dave! (01:01.251)
                        Are there? Can you think of one? Are you thinking of Uncle Sam? Let me tell you about those movies. Neither are very good. And Uncle Sam is weird propaganda.</p>
                        <p>Bryan! (01:11.74)
                        I you know, Uncle Sam is so fucked up. It&#39;s so stupid too. I know what you did last summer is one of the sort of scream clones and is you know, seeing as I&#39;m not really a big fan of scream. I did not really give a fuck about that one or urban legend or any of the kind of cash ins that sort of jumped in its way. No, no my</p>
                        <p>Dave! (01:19.51)
                        Weird political propaganda.</p>
                        <p>Bryan! (01:38.172)
                        My go-to July 4th movie is Jaws. And I understand you&#39;re a fan of it as well. Probably even a bigger fan of it than I am. And I...</p>
                        <p>Dave! (01:44.204)
                        I.</p>
                        <p>I probably am. I think this is probably the first movie I remember really loving.</p>
                        <p>Bryan! (01:52.36)
                        I remember, yeah, like when we were kids, like I, you really embraced this one way before I did. Like I&#39;d seen it, like a shitload by the time, by that point, but like, like this, this really was like favorite movie territory for you.</p>
                        <p>Dave! (02:08.074)
                        It&#39;s also a movie that the enjoyment changes over time, but I think the meaning changes over time as well. Like what is about, I think upfront we should say, this is one of those ones that walks the line of like, wait, is this actually a horror movie? Which I chalk that up to gatekeeping and I don&#39;t have use for gatekeeping really, but it is a good question.</p>
                        <p>Bryan! (02:26.176)
                        Ah, that&#39;s a good.</p>
                        <p>I mean you make a perfectly good point. I personally have always considered it a horror movie, but I can see where others might not. It sort of depends on kind of where you land on like what is a horror movie because a lot of people kind of still really consider like this is this is quality cinema in a lot of ways. You know, like, because we&#39;re gonna we&#39;re gonna talk about this in depth.</p>
                        <p>Um, yeah. Yeah. Mm hmm. Right.</p>
                        <p>Dave! (03:02.038)
                        I think the best description I&#39;ve ever heard, or the best way of kind of parsing that, that I&#39;ve ever heard, I think it was on that podcast, RUINED, where they said, the best way to describe a horror movie is, is this movie designed to frighten you? And if it is, then the answer is yes, it is a horror movie. So something like Silence of the Lambs, that movie is not designed to scare you. It&#39;s designed to thrill you in certain parts, but it&#39;s not made to be a frightening film. This, I think on the other hand, is.</p>
                        <p>intended to be a frightening film, among many other things.</p>
                        <p>Bryan! (03:33.557)
                        And I yeah I really came I came around to the sort of scary part kind of late mostly because I was really shitty when it came to horror movies. Like I don&#39;t know what it is like there are very, very few horror movies that I say scare me there&#39;s plenty that thrill me and excite me and very, very weird ways but scare me.</p>
                        <p>move there really aren&#39;t any that really kind of scare me but this movie has touches to it and like every time i watch it i see a little something more to it that really kind of that kind of moves me in that direction but like yeah it&#39;s my god like when they we&#39;ll get to it</p>
                        <p>Dave! (04:10.735)
                        And it has legit jump scares in it. Like more than one.</p>
                        <p>Bryan! (04:19.568)
                        We&#39;ll get to it. But yeah, so really before we get into it, a little housekeeping. If you want to keep up with us between episodes, you can also find us on Blue Sky, Twitter, Instagram and Twitch, GrimrosePod. Kind of not using Twitter these days because it sucks and it&#39;s a real piece of shit. And Elon Musk is just doing his best to fuck it up for everybody. So if you&#39;ve got coveted invites to Blue Sky, you can find me there.</p>
                        <p>We&#39;ve also got a sweet website at brothersgrimpodcast.show. That&#39;s dot show, that&#39;s important. Dot com takes you to another Brothers Grimm podcast. And you can listen to all our past shows there and read the transcripts if that&#39;s the sort of thing you&#39;re into. And you can also contact us directly at grimbrospod at gmail.com with any questions, comments or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear.</p>
                        <p>you can subscribe to us wherever you get your podcasts and you&#39;d be doing us a favor by leaving us a five-star review on apple podcasts takes a second and it really does actually go a long way and sort of you know helping us sort of gain visibility because as it turns out uh i thought i was being really clever with a name like the brothers grim it turns out there&#39;s like six podcasts called the brothers grim so yeah i know too late we could have been bring me the axe i know</p>
                        <p>Dave! (05:36.919)
                        It&#39;s too late now. Ah, Jesus. Okay, it&#39;s not, it might not be too late, actually. I&#39;m just saying, think about it, just think it over.</p>
                        <p>Bryan! (05:46.304)
                        I know this is only the 11th one. Yeah, yeah. So yeah, I just want to get that all out of the way right at the top of the show. So we already said we&#39;re watching, we&#39;re watching Jaws. So let&#39;s give you a little taste of Jaws in what is a really, really long trailer. I was not expecting this.</p>
                        <p>There it is, Jaws. That is a crazy trailer.</p>
                        <p>Dave! (09:38.071)
                        God damn, you know, I got a lot of thoughts just about the trailer alone. First of all, fuck Universal for demonizing this poor animal.</p>
                        <p>Bryan! (09:50.908)
                        It&#39;s a perfect killing machine, the devil with jaws.</p>
                        <p>Dave! (09:51.159)
                        See you next time.</p>
                        <p>This is Universal&#39;s version of Peter Benchley&#39;s god-awful book, Jaws.</p>
                        <p>Bryan! (10:00.196)
                        I gotta tell you, I got notes in here about the book. The book, it sucks. Most of, I don&#39;t care. Like I&#39;ve also, I&#39;ve read, I&#39;ve read the deep and I read, oh, there&#39;s one, oh, shit. It, all right, it&#39;ll come to me. It&#39;s another, it&#39;s another one that got picked up for production, but I can&#39;t remember what.</p>
                        <p>Dave! (10:04.738)
                        Have you read the book? It&#39;s fucking terrible.</p>
                        <p>Was it the beast?</p>
                        <p>Bryan! (10:27.436)
                        No, it was not the Peter Benchley&#39;s beast, the one that they did on TV.</p>
                        <p>Dave! (10:31.264)
                        The other one is Great White.</p>
                        <p>Bryan! (10:33.undefined)
                        No, no, that&#39;s something else. I don&#39;t know. I&#39;m blanking on it. I&#39;ll come back to it. But.</p>
                        <p>Dave! (10:37.77)
                        He&#39;s not a very good writer and this book is fucking terrible.</p>
                        <p>Bryan! (10:41.944)
                        It&#39;s so bad. Nobody is likable. Everybody&#39;s an asshole. There isn&#39;t a lot, like the script for this movie is a massive, massive improvement over the book. And apparently Ben Shelley was kind of pissed off in the way that it came out, to which I say, he wrote three drafts of the script and then Spielberg took it. We&#39;ll get there. So.</p>
                        <p>Dave! (10:57.55)
                        What he did, didn&#39;t he write the script?</p>
                        <p>Bryan! (11:06.136)
                        Warning before we get rolling, we&#39;re going to talk about this movie from beginning to end, so spoilers to follow. So what the fuck is wrong with you if you have not seen... Jesus Christ. So the year was 1975. Other movies released that year. Autopsy, which is a jello that I&#39;ve got a special place in my heart for. It is a supremely fucked up one.</p>
                        <p>Dave! (11:10.518)
                        Yeah, if you haven&#39;t seen this movie, shut up. You&#39;ve never seen a movie before.</p>
                        <p>Bryan! (11:35.388)
                        Which have you ever seen Deathula? I have, I reviewed it years and years ago for Cinema Suicide. It is a fucking weird movie. It&#39;s Dracula told entirely in American Sign, but it goes to some really, really weird places. It&#39;s very funny. Also, Profondo Rosso.</p>
                        <p>Dave! (11:37.074)
                        I have not.</p>
                        <p>Meh.</p>
                        <p>Bryan! (11:56.904)
                        Deep Red, I like Deep Red, it&#39;s a decent one. Sallow or the 100 Days of Sodom, movie will make you wanna take a shower and you&#39;ll never have enough showers. Yep, and finally the Rocky Horror Picture Show. It&#39;s a fun one, it&#39;s great, right? Cast and crew, director, Steven Spielberg, Mr. Hollywood.</p>
                        <p>Dave! (12:02.089)
                        It&#39;s fine.</p>
                        <p>That is not fine.</p>
                        <p>and wash your eyes.</p>
                        <p>Dave! (12:14.89)
                        Hey, that&#39;s a fun one.</p>
                        <p>Bryan! (12:23.884)
                        ostensibly the most successful feature director of all time. I, you know, I&#39;m not either. I, so, I, I consider this, I consider this up to be a perfect movie. Everything about it is perfect. There is not, and there are basically, there&#39;s two movies that I consider perfect. There&#39;s this,</p>
                        <p>Dave! (12:27.454)
                        and arguably one of the greatest directors of all time. And I&#39;m not even a huge fan.</p>
                        <p>That&#39;s no Black Christmas, but okay.</p>
                        <p>Bryan! (12:52.132)
                        And the other one is also directed by Spielberg, Raiders of the Lost Ark. There is not a, this movie runs a touch over two hours long, not a minute, not a second is wasted. Even at that running time, it is lean as fuck. I don&#39;t know. I consider, I think it&#39;s just, it&#39;s paced perfectly. Everything is great. It&#39;s got, it&#39;s so good. And this is Spielberg&#39;s, so up to this point, Spielberg had done a bunch of television.</p>
                        <p>Dave! (13:05.873)
                        It drags a little bit in the second act.</p>
                        <p>Bryan! (13:22.056)
                        He&#39;d done some TV movies, most notably, like the one that everybody knows is Duel, because this is basically Duel, but with like a shark and... Duel&#39;s great, yeah. Oh, um, are you afraid? No. Yeah, so the feature he did before this is a Goldie Hawn picture called The Sugarland Express, which is, it&#39;s...</p>
                        <p>Dave! (13:28.426)
                        And Duel is a good movie. And the other one, I can&#39;t remember. It&#39;s like something about being afraid. It has Robert Stack in it. It&#39;s it&#39;s something it&#39;s not a horror movie. It starts out as one, but it&#39;s really not. It&#39;s a decent movie. I just can&#39;t remember the name of it.</p>
                        <p>Bryan! (13:52.992)
                        crime drama essentially about a couple who are trying to sort of like kidnap their kid before he goes into foster care and then there&#39;s like a jailbreak and stuff. I&#39;ve never seen it, not super interested in it. But this is the movie that put him on the map, put him on the mind of everybody. Jaws made so much fucking money that it gave Spielberg enough studio goodwill to basically do whatever he wanted with whoever he wanted even after he flopped super hard on 1941.</p>
                        <p>Spielberg, for my money, kind of broke the industry out of the of the auteur mindset that was going on at this time. You put popcorn back in movie theaters and before and before Jaws, the notion of the summer blockbuster was non-existent. It was not really a thing. There were movies that were like a big deal, but this was the movie that suddenly kind of put it in everybody&#39;s idea like in everybody&#39;s head that like we can make sort of like a tent pole movie that hits in the summer gets everybody&#39;s money.</p>
                        <p>because this was really sort of the beginning of that.</p>
                        <p>Dave! (14:55.991)
                        But I think that goes along with what I was thinking about when like trying to imagine the world in which this movie came into is so different from the one we live in now that to see this. So first of all, no one had really seen a Steven Spielberg movie yet. Not not what they would become. And so all the shit that he does.</p>
                        <p>Bryan! (15:09.325)
                        Yeah.</p>
                        <p>Dave! (15:20.022)
                        that is really incredible. No one had seen it yet. So it was truly incredible to see. Politically, it&#39;s a very different world, but also just the idea of scuba diving. Like scuba diving wasn&#39;t really a thing people did. People didn&#39;t know shit about sharks. They didn&#39;t know that there were different kinds. I&#39;m sure they did, but based on their face shape alone, that&#39;s it. Everything about this movie is so...</p>
                        <p>Bryan! (15:44.193)
                        Yeah.</p>
                        <p>Dave! (15:47.982)
                        fresh and real and that&#39;s probably why it was so terrifying. I mean, and there&#39;s a huge downside to that in that people started killing sharks like it was going on a fucking style.</p>
                        <p>Bryan! (15:58.948)
                        Yeah, it really did make an impression on the public. Like when the sequel comes out, and fuck the sequels, but like when the sequel comes out, the tagline was just when you thought it was safe to go in the water again. Like that idea really got to people. Like it was sort of like the exorcist like a year before where like this was something that really kind of got into the zeitgeist and had a kind of lasting effect on the way that people sort of looked at the world. It&#39;s really fucked up.</p>
                        <p>Dave! (16:27.809)
                        Yeah, it is a fact. This shark will possess you.</p>
                        <p>Bryan! (16:31.908)
                        Yep. There is a shark exorcist movie. It&#39;s called shark exorcist and they fucking slept on sharks or cyst. How do you do that? Morons. I we should write this shit. So cast Roy Scheider as Chief Martin Brody. He&#39;s a quintessential 70s guy. Really? Yeah.</p>
                        <p>Dave! (16:35.086)
                        ShackSarcist.</p>
                        <p>He&#39;s your everyman. He is he is us, the viewer.</p>
                        <p>Bryan! (17:02.undefined)
                        Basically, yeah, he is not, he&#39;s not like a tough guy. He&#39;s not, he&#39;s just, it&#39;s actually.</p>
                        <p>Dave! (17:06.378)
                        And I&#39;m gonna get to that later because I think that is extremely important to this movie.</p>
                        <p>Bryan! (17:10.176)
                        Yeah, I&#39;ve got a note really right at the end of this that I&#39;m sure like we&#39;ll hit it at a certain point But like it&#39;s his role in this movie is kind of weird um, we&#39;ve got Richard Dreyfuss who I don&#39;t I Here&#39;s the thing I don&#39;t know anything about Richard Dreyfuss to be honest. Uh, I think as an actor he&#39;s fine. Um He&#39;s uh, Matt Hooper the oceanographer in this one obviously, uh another</p>
                        <p>Dave! (17:20.024)
                        sucks.</p>
                        <p>I mean, as a person, Richard Dreyfuss sucks. He&#39;s fine in the movie.</p>
                        <p>Bryan! (17:39.652)
                        sort of quintessential 70s, 80s guy. You know, he&#39;s, I think he stars in, he comes back with Spielberg in the next feature. He does Close Encounters. This is another movie that I love to death.</p>
                        <p>But yeah, and then Robert Shaw as Quint, salty old man of the sea.</p>
                        <p>Dave! (18:00.566)
                        Yeah, Robert Shaw is basically playing himself in this movie.</p>
                        <p>Bryan! (18:04.389)
                        I don&#39;t know if I&#39;ve ever seen Robert Shaw in anything but this movie.</p>
                        <p>Dave! (18:06.902)
                        because he was not in much at this period. He was a raging alcoholic and was extremely difficult to work with. And so, yeah, he was a guy, you know, 40s, 50s, sort of 60s guy, but he had gotten such a bad reputation because he was such an awful drunk and he would just not show up half the time. And so they, you know, he kind of wasn&#39;t, but he was exactly what they were looking for. Initially, the studio had wanted Charlton Heston to be...</p>
                        <p>Bryan! (18:12.796)
                        Uh, he was kind of, he was like a, he was like a westerns guy, wasn&#39;t he?</p>
                        <p>Yeah.</p>
                        <p>Bryan! (18:26.045)
                        Yeah.</p>
                        <p>Bryan! (18:31.454)
                        He&#39;s-</p>
                        <p>Dave! (18:35.958)
                        the Brody character. Do you know the story about why he&#39;s not in it? Apparently, Steven Spielberg, when he was like out just out of film school, he would go to Universal and just like do anything. He&#39;d be like, I&#39;ll do anything for free. Just give me work. And he would hang out in the commissary and he would just kind of go up to like Cary Grant, like, hey, Cary Grant, come have lunch with me. Come sit with me at lunch. You know, it&#39;s such a forward thing that I&#39;m sure most of them were like, oh, OK. Who are you again?</p>
                        <p>Bryan! (18:37.931)
                        Yeah! No, I do not.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (18:59.784)
                        Hmm.</p>
                        <p>Bryan! (19:04.7)
                        Yeah, yeah.</p>
                        <p>Dave! (19:04.886)
                        But he did that to, well, supposedly, I don&#39;t know if this is actually true, but he did it to Charlton Heston, and Heston blew him off. And he was like, well, you will never be in one of my movies. And I&#39;m sure at the time he was just like, whatever, dude. And it was like, well, also.</p>
                        <p>Bryan! (19:19.006)
                        That&#39;s awesome.</p>
                        <p>Yeah, yeah. And then, yeah, like two years later, he&#39;s like the biggest fucking deal in the world. He&#39;s shaping. No, no, no. Spear, spear, oh. Fucking dead, isn&#39;t he? I hope so. Fuck him.</p>
                        <p>Dave! (19:29.186)
                        And Charlton Heston? Where is he now? I don&#39;t know. I hope so.</p>
                        <p>Bryan! (19:37.972)
                        So some notes, this movie was originally given to a director named Dick Richards, who was, I love this. This fact is like one of my favorite facts of any movie fact ever. He was dropped from the project because he couldn&#39;t stop describing the shark as a whale when talking about the script. I gotta tell you, I&#39;d be fucking annoyed as hell if the guy I was trying to hire to do my shark movie kept calling it a whale. Get the fuck it.</p>
                        <p>Dave! (19:59.675)
                        It sounds like, dude, it&#39;s on the cover of the book. It&#39;s in the name.</p>
                        <p>Bryan! (20:02.476)
                        I know. Oh yeah. Yep, so this was actually, this movie was, like it&#39;s sort of the way that it works with a lot of these guys who kind of come into the system very fresh is, when you come in, Kevin Smith tells stories about this too. It was kind of how he ended up on Superman, but Spielberg&#39;s entry pointing to this was also very similar where.</p>
                        <p>He signed a deal with Universal and they were like, here&#39;s like six movies, which one of these ones do you want to do? And he was like, Jaws, I want to do Jaws. And then they were like, okay, well, why don&#39;t you also do this one? And that&#39;s how he kind of came around to Sugarland Express. So like he had Jaws before he&#39;d really done anything for Universal. They bought it before it was even published. Yeah, yeah.</p>
                        <p>Dave! (20:42.648)
                        Because they bought the rights to the movie book or before it was even out, which is pretty rare.</p>
                        <p>Bryan! (20:50.32)
                        I have heard a lot of stories, particularly around this time, because I think when we talked about it in the Sentinel, it was the same deal where, well, that guy wrote the book. Yeah, but a lot of these movies, particularly at this time, were getting optioned before they&#39;d been published, which is crazy. Yeah.</p>
                        <p>Dave! (20:58.158)
                        but he wrote the book in order to get the movie made.</p>
                        <p>Because they were buying, they would just buy up anything at the time because there was so much cool shit coming out.</p>
                        <p>Bryan! (21:15.34)
                        I wonder if the Godfather had anything to do with that, because I think Mario Pr-</p>
                        <p>Dave! (21:18.322)
                        I think that, I think what&#39;s the French connection? I think it&#39;s another one. The Godfather probably did.</p>
                        <p>Bryan! (21:22.66)
                        Yep. Yeah. Like the Oh, yeah. And again, the exorcist, like people just optioned books that turned into humongous big deals because before this movie came out, The Godfather was the most like the highest box office gross, like in Hollywood history or something like this. And it was just like the way that&#39;s just the way that like Hollywood logic, like studio logic works where it&#39;s like, so they&#39;ll</p>
                        <p>pinpoint some quality of the of the movie that was like that&#39;s the thing that made that made us all the money and they always kind of landed on adapted from a novel was the thing and so everybody was buying novels even if they sucked like jaws. Speaking of which this is based on a novel by Peter Benchley and they said the first few drafts were written by Benchley who also appears in this movie as in a cameo as a reporter kind of and they sort of talk about it in a bit of dialogue.</p>
                        <p>Dave! (22:11.164)
                        Also based on a true story, kind of.</p>
                        <p>It&#39;s based on a couple different stories, actually.</p>
                        <p>Bryan! (22:17.252)
                        Yeah, yeah. There was a bunch of shark attacks in the teens around New Jersey. Yeah. But the original novel is a much different story than the movie that we got. Yeah, the characters are mostly dour. There&#39;s 1,000 subplots that really don&#39;t fucking add up to anything. The reason that that.</p>
                        <p>Dave! (22:24.406)
                        That&#39;s that&#39;s the one in New Jersey.</p>
                        <p>It&#39;s about the mob and people having affairs.</p>
                        <p>Bryan! (22:43.848)
                        the sort of town fathers of Amity are so dead set against closing the beach is because the mob doesn&#39;t want them to do that because they own all this property and they don&#39;t want to see the property lose value. And so like that&#39;s kind of the motivating factor. There&#39;s a whole subplot where like Matt Hooper has a has an affair with Brody&#39;s wife. Thank God they got rid of that shit. But</p>
                        <p>Dave! (23:05.386)
                        Yeah, literally everyone in that book sucks. Like, I hope you all get eaten. I don&#39;t give a fuck about any of you.</p>
                        <p>Bryan! (23:07.78)
                        Everybody, it&#39;s very, it&#39;s dour.</p>
                        <p>Yeah, yeah, so it.</p>
                        <p>Bryan! (23:17.828)
                        So when Spielberg got it and he got Benchley&#39;s drafts, he then took it to a guy named Carl Gottlieb, who was a writer on the Odd Couple TV show. And basically he took it to him for kind of punch up. And what Gottlieb ended up doing was just writing an entire draft of the script that whittled it down to essentially what we got. Like eventually they sort of tuned it on the set, but Gottlieb&#39;s draft</p>
                        <p>Whittles it down to a central premise of shark attack and getting the shark and it gets rid of all of the side shit And it adds kind of a buddy cop dimension to it And then right really right before it while it was in production actually John Milius Provided polish and a lot of the dialogue for Quint which seems apropos</p>
                        <p>Bryan! (24:13.032)
                        There are three sequels to Jaws, each one getting progressively worse than the last one until the final sequel, Jaws the Revenge, which is a notorious pile of garbage. The third Jaws dropped right in the middle of the 80s 3D revival. It&#39;s also incredibly bad, but the 3D is pretty fun in parts. Following this, the exploitation machine lurched into action, the series of shark exploitation movies, which continue to this day, proving to...</p>
                        <p>Dave! (24:39.762)
                        Oh yeah, no, this launched so many awesome animal attacks. Now I love the animal attack genre. And this, this gave us so many good ones.</p>
                        <p>Bryan! (24:44.464)
                        Hahaha</p>
                        <p>Oh, yeah. Yeah, so it just kind of proves the world. Everybody loves sharks, no matter how bad they always are. So there&#39;s contemporary ones like Sharknado, Cocaine Shark, Shark Exorcist. And then there&#39;s also classics like, yeah, I don&#39;t give a fuck about those ones. But yeah, make an Amityville movie. Yeah, there&#39;s also classics like Tentacles.</p>
                        <p>Dave! (25:01.354)
                        And I say, fuck all of those movies and the people who made them. Get the fuck out of here. Make a real movie, you jack off.</p>
                        <p>Yeah.</p>
                        <p>Dave! (25:17.342)
                        Ah, Tentacles. That is also one of my favorites. It is an extremely boring movie. And it makes no sense.</p>
                        <p>Bryan! (25:18.76)
                        Uh, tend to... Ha ha ha, yup. None, none. But it&#39;s what happens when you let the Italians go at it. Also, Piranha. Which I love. I think Piranha is silly as hell, but also it&#39;s supposed to be silly. Yeah, yeah.</p>
                        <p>Dave! (25:31.265)
                        Ugh.</p>
                        <p>No, it&#39;s a great movie. Even Steven Spielberg was like, that is way better than it has any right to be.</p>
                        <p>Bryan! (25:41.732)
                        Yeah, yeah, yeah. That was Dante. That was Joe Dante. And the sequel was James Cameron.</p>
                        <p>Dave! (25:50.59)
                        And the music in the sequel? Stelvio Cipriani. Music in the first one, Pino Di Nacio.</p>
                        <p>Bryan! (25:53.196)
                        Yes, yep, yep. Good music all around, it&#39;s crazy. Jazz is just, it&#39;s the gift that keeps on giving. There&#39;s...</p>
                        <p>Dave! (26:02.318)
                        It seriously is. You want to know a real funny thing about Jaws? Is that Steven Spielberg believed that they could just train the shark to do this stuff. Someone had to tell him, you cannot train a shark.</p>
                        <p>Bryan! (26:10.568)
                        Yes, I have a note in here. I have a note in here that was like, that was like, you can&#39;t do that. Are you insane? Yep, shot on location around Martha&#39;s Vineyard. You could go there and tour the locations. It&#39;s a bit of a small cottage industry. And every time there&#39;s like an important sort of anniversary for the movie, like they roll it out and they do the whole thing. It&#39;s like a big like Jaws.</p>
                        <p>celebration. You can go there and watch Jaws at Amity Island. Most of the locations have remained unchanged for the most part. There are a couple of websites that give you maps of the locations. While you&#39;re there, you can also see Wes Craven&#39;s grave site.</p>
                        <p>Bryan! (26:54.756)
                        Here&#39;s the note I have a note in an exhibit of Hollywood cluelessness. They thought that they could train a great white shark for the movie. Had to be convinced by several people that was not a thing you can do and is quite literally impossible. So they settled on a series of mechanical sharks which notoriously malfunction all the goddamn time leaving Spielberg with no choice but to be quite conservative with how he shot the shark. This ends up being one of the movie&#39;s greatest strengths.</p>
                        <p>Dave! (27:20.866)
                        There are actually real sharks in this movie, not shot by Steven Spielberg. They&#39;re shot by Ron and Valerie Taylor, who were like underwater photographers, kind of. They have a documentary that they made. They were really famous for filming sharks like cage free diving and all that shit, like back in the late 60s and 70s. So there&#39;s a documentary called Innerspace that you can watch. It&#39;s like 40 minutes or something. It&#39;s on. You can find it on YouTube. It&#39;s really cool to just see them. But then eventually they got into like</p>
                        <p>Bryan! (27:25.269)
                        Oh, okay.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (27:38.671)
                        Oh yeah.</p>
                        <p>Bryan! (27:43.09)
                        Yeah.</p>
                        <p>Dave! (27:50.262)
                        conservation and shit, but this, so they shot like the stuff at the end where he&#39;s in the cage and all that. That&#39;s them filming. That is a real shark.</p>
                        <p>Bryan! (27:56.268)
                        Oh yeah, because that&#39;s remarkably, I did not, I did not know that because that&#39;s, those are remarkably awesome shots. So.</p>
                        <p>Dave! (28:05.002)
                        Yeah, they were like in the 70s, in like 60s and 70s, they were consultants on a lot of movies just because they were like the best of the best.</p>
                        <p>Bryan! (28:15.532)
                        Yeah. So the full body shark is nicknamed Bruce was named after like Spielberg&#39;s lawyer or something like that. Yeah, the great white in Finding Nemo is named after him and so the I. Yep, yep.</p>
                        <p>Dave! (28:23.967)
                        You know, ha</p>
                        <p>That&#39;s straight white guy humor, I think.</p>
                        <p>Bryan! (28:38.696)
                        The iconic score is famously provided by John Williams, who between Spielberg and George Lucas never got arrested until about 1985, probably. It&#39;s endlessly... Are you kidding me?</p>
                        <p>Dave! (28:51.658)
                        And Spielberg did not like the music. He thought it was a joke when he first played it for him. He was like, oh, that&#39;s great. Now let&#39;s hear the real score. And Williams was like, what? What did you just say to me, Steven Spielberg?</p>
                        <p>Bryan! (29:03.036)
                        Williamson had been like, fuck you, man. I conducted the Boston Pops, asshole. Yeah.</p>
                        <p>Dave! (29:08.918)
                        Well, I think so. Spielberg really liked him because there is something very old timey about John Williams music, because this is at a time when like people like John Carpenter are doing electronic stuff like that is the style that Williams learned in and was kind of he was he&#39;s very, very late to the game. It&#39;s very Bernard Herrmann esque in a way that kind of big orchestral stuff.</p>
                        <p>Bryan! (29:20.344)
                        Yeah.</p>
                        <p>Hmm.</p>
                        <p>Dave! (29:34.718)
                        And nobody did it. But of course, Steven Spielberg is one of the most nostalgic people in the world. And so that&#39;s why he was like, I need someone to do this. And he hurt something. I can&#39;t remember what score it was, but he was like, oh, I got to get this guy, you know, before he dies. He must be so old. And he was like, hey, dude, no, I&#39;m not. And then so, yeah, he played this music and he was like, what is this? This is not what I was expecting. He was like, just fucking go with it, man.</p>
                        <p>Bryan! (29:39.453)
                        Yeah, no shit.</p>
                        <p>It&#39;s perfect. Oh my God. The thing I love about John Williams in particular is whenever he comes up with, whenever he has, he always has like a central composition and in that composition, you can sing the name of the movie. It&#39;s in this, it&#39;s.</p>
                        <p>Dave! (30:17.278)
                        Oh yeah, here, let me tell you this one. And some dinosaurs, fucking dinosaurs. Yeah.</p>
                        <p>Bryan! (30:23.261)
                        It&#39;s Jurassic Park, Star Wars, Superman, like all of them. You can sing along with it. I love that quality of his music.</p>
                        <p>Dave! (30:32.278)
                        And I am actually not a big John Williams fan, just because I&#39;m not a big, like I&#39;m a big fan of Bernard Herrmann, but I don&#39;t need to hear someone else do Bernard Herrmann. I can just listen to him do it.</p>
                        <p>Bryan! (30:36.273)
                        Bye!</p>
                        <p>I know, I appreciate it. I appreciate his contributions to these movies because like, I&#39;m also a big Star Wars fan and you know, like it&#39;s, everybody knows that. People were fucking disco dancing to the Star Wars theme. Like it&#39;s ubiquitous and like, but I&#39;m not a fan of his. I think that there are other composers who are way better. It&#39;s just, it&#39;s not my style. You know, I tend to be a little bit more like.</p>
                        <p>I like the noodley synthesizer stuff personally, or the kind of...</p>
                        <p>Dave! (31:09.598)
                        But he definitely, he does a thing and he does it really fucking well. I mean, especially now when everyone else who probably did it better is dead.</p>
                        <p>Bryan! (31:13.436)
                        Yeah, yeah, and it&#39;s.</p>
                        <p>Yeah, yeah. Endlessly Parody, the score was the foundation of Harry Madfordini&#39;s Friday the 13th score, lifted because he saw Pamela Voorhees as a sort of shark in that.</p>
                        <p>Bryan! (31:33.128)
                        So this was originally budgeted at $3.5 million. The movie went notoriously over budget.</p>
                        <p>Dave! (31:39.594)
                        I think it&#39;s 300 times over budget or something like that.</p>
                        <p>Bryan! (31:42.776)
                        Uh, it was, uh, yeah, it, it ultimately came down to about $9 million. The studio was sweating it super, super hard. Um, but a lot of the overrun came from, they had to, they, they invented entirely new processes for filming. No one had ever filmed on the ocean before.</p>
                        <p>Dave! (32:03.662)
                        That is because the sky over the ocean has a tendency to change very, very quickly.</p>
                        <p>Bryan! (32:09.392)
                        Yeah, so it came out to about nine mil. Yeah, yeah, so ends up at around nine million went on to gross over 100 million during its initial run, which is. A significant amount of money in 1975.</p>
                        <p>Dave! (32:11.146)
                        So there&#39;s that, that affects continuity.</p>
                        <p>Bryan! (32:26.488)
                        It&#39;s absolutely humongous. This is the reason why Spielberg was basically allowed to do whatever he wanted because whatever he did next, they just assumed everybody&#39;s gonna go see this movie. The studios weren&#39;t wrong. It was a box office record until Star Wars, and then it&#39;s been released several times and run several times in theaters. Has a total box office gross of nearly $500 million today, which is nuts.</p>
                        <p>There is a whole weird true crime angle to this that&#39;s wrapped up where the author Joe Hill circulated this hypothesis that the murder victim, a woman named Ruth Marie Terry who was really only named just recently, was known for years as an unidentified victim, Nicole Lady of the Dooms.</p>
                        <p>Dave! (33:16.219)
                        Oh, the lady in the dunes.</p>
                        <p>Bryan! (33:20.536)
                        His thought is there&#39;s an extra in this movie who sort of matches the description of the woman who was found. I don&#39;t know if there&#39;s any truth to it. I doubt it. Probably not. You get your own thing going on.</p>
                        <p>Dave! (33:30.542)
                        Probably not. Get Joe Hill, get out of here with your bullshit. You second rate Stephen King. Yeah, coming for him. Well, I&#39;m sure he&#39;s a nice guy.</p>
                        <p>Bryan! (33:40.961)
                        You know I met him? I went to... He is a nice guy. I went to his house and played Trivial Pursuit with him.</p>
                        <p>Dave! (33:48.558)
                        Was he any good at it or?</p>
                        <p>Bryan! (33:50.136)
                        He beat me. Yep, I caught him cheating though.</p>
                        <p>Dave! (33:54.958)
                        I cannot fucking trust that family.</p>
                        <p>Bryan! (33:56.54)
                        You can&#39;t trust it. Can&#39;t trust a king. What the hell is wrong with these guys? I don&#39;t know. Now, yeah, he used to, I don&#39;t know. He might still live there. He&#39;s like, he lives like five minutes from me. And we had some friends in common. And so they were like, hey, why don&#39;t you come over and play Trivial Pursuit at Joe Hill&#39;s house? And I was like, say no more.</p>
                        <p>Dave! (34:16.278)
                        You know what, if someone asked me, hey, do you want to come play Trivial Pursuit at Joe Hill&#39;s house? I&#39;d be like, not really.</p>
                        <p>Bryan! (34:24.225)
                        Let me tell you, his house has an actual secret passage behind a bookcase and you open it by pulling a book. I thought it was awesome.</p>
                        <p>Dave! (34:31.506)
                        Okay, I mean, I&#39;d be like, can I use the bookcase thing? And then maybe, then maybe I&#39;d go, but probably not.</p>
                        <p>Bryan! (34:41.152)
                        Um, there is uh, I don&#39;t know there&#39;s so much to talk about we could go on and on about it It&#39;s a truly historic film one of the greatest movies of all time It&#39;s a picture that</p>
                        <p>Dave! (34:50.722)
                        that should be said that, you know, as much as this gets, you know, biggest summer blockbuster starts the whole fucking movement. You know, Stephen King, not Stephen King, Steven Spielberg, all that. This Steven Spielberg is who he is for a reason. Like, yeah, he makes really accessible family fare, but he is a really fucking good director. Like, there is some genius shit in this. And a lot some of it&#39;s ripped off from Hitchcock, but</p>
                        <p>Bryan! (35:06.9)
                        Mm-hmm.</p>
                        <p>I right up front.</p>
                        <p>Dave! (35:19.67)
                        For the most part, like, this is really, really innovative shit.</p>
                        <p>Bryan! (35:24.772)
                        Yeah, and like in other movies, they would, the directors would dedicate entire cast members to sort of exposition pieces, info dumps, but there are things in this movie that Spielberg does that sort of like explains what exactly what&#39;s happening simply through body language, through like a shot like.</p>
                        <p>Like, what happened to the girl at the very beginning? Like, what&#39;s the cause of death? And what we see is a close-up of paperwork as he enters shark attack and the cause of death. Like, it&#39;s little things like that just really sort of add to the sort of economy of time that happens in this movie. It&#39;s really, he is 100% worthy of all the praise that he got, because I mean, the guy is just that fucking good. And I don&#39;t know why, but for a long time,</p>
                        <p>Dave! (36:11.44)
                        And I think, well, I think part of this is, is that like he is the only one out of that whole fucking group of like, you know, all the directors who came out around that same time. He&#39;s the only one that looked back at things because he&#39;s so nostalgic and he loved all that old shit. He looked at it and saw what brilliant stuff that was as well.</p>
                        <p>Bryan! (36:28.382)
                        Yeah.</p>
                        <p>Mm-hmm. Yeah.</p>
                        <p>Dave! (36:32.43)
                        You know, like Billy Wilder and all these people, and he took what he could from them. Whereas everybody else is like, I&#39;m too fucking smart for that. I&#39;m going to make my own shit. It&#39;s like, well, that&#39;s why he&#39;s Steven Spielberg and your Francis Ford Coppola. Not to say that Coppola has not made good movies and is not successful, but he&#39;s a little fucking schlocky.</p>
                        <p>Bryan! (36:39.172)
                        Yeah, yeah, I think the thing...</p>
                        <p>But he was up his own ass. Yeah, yeah. A lot of those guys, I think that was where that&#39;s really it is him and like George Lucas. Definitely not up their own ass in the way that the other guys were. So weirdo is me, but uh. Yeah, yeah. Hey. He is a he&#39;s an he&#39;s an asexual dude.</p>
                        <p>Dave! (37:00.422)
                        No, George Lucas is too autistic to be up his own ass. I mean, his wife left him for not having sex with him. That is literally why she left him, which is fair. That&#39;s, yeah, but she didn&#39;t know that going in.</p>
                        <p>Bryan! (37:17.128)
                        That&#39;s a bit presumptuous, yeah. But yeah, so we open on a beach party at night. Kids all smoking bots, drinking beers. There&#39;s a drunk kid leering menacingly at a girl who sits alone next to a burning garbage can.</p>
                        <p>Dave! (37:35.586)
                        And you know, like she&#39;s, you know, she&#39;s like pretty in like a kind of like a, like a floppy sissy spacey kind of seventies way.</p>
                        <p>Bryan! (37:42.3)
                        Yeah, yeah. It&#39;s the 70s and everyone&#39;s shaking off the chains of sexual oppression and these glances translate into the kid drunkenly following the girl along the dunes as she joyfully sheds her clothes and jumps into the ocean. And this is this what like right out of the gate like I watched the 4k of this, and it is a fucking masterclass in digital presentation. It is.</p>
                        <p>Dave! (38:06.582)
                        This scene is incredible because it goes, it&#39;s that it&#39;s that Pixies thing. It&#39;s that loud, quiet, loud where you start with that movement and she&#39;s doing that kind of water ballet bullshit. And the shot is framed beautifully. Everything looks incredible. It&#39;s.</p>
                        <p>Bryan! (38:16.017)
                        Yeah.</p>
                        <p>And it&#39;s so fucking sharp. Previous home video releases of this movie, even in the past DVD releases, were murky enough that you could actually show this opening scene on TV. Like I remember this, the very first time I ever saw this movie was on TV, and this entire scene was in it.</p>
                        <p>Dave! (38:36.918)
                        This movie is, this scene is legitimately terrifying.</p>
                        <p>Bryan! (38:40.944)
                        Yeah, and there&#39;s and this was shown without cuts for nudity at the time, but in 4K you can&#39;t do that anymore because like it is It&#39;s it&#39;s brighter. It&#39;s crisp All this stuff that was really supposed to be kind of like concealed in shadow is now really up front um It&#39;s it&#39;s probably the best 4k i&#39;ve ever seen But we get some menacing her name is chrissey, by the way, I don&#39;t think we ever actually get the guy&#39;s name</p>
                        <p>But we get some menacing shots of her, the swimmer alone, from below as the shark stalks its prey before she is suddenly pulled under and then dragged around the harbor by an unseen underwater assailant. And this scene is parodied, a shitload, but it&#39;s parodied in one of my favorite scenes from Ace Ventura, the, it&#39;s not snowflake part. She&#39;s dragged around until she&#39;s finally dragged under while the idiot drunk boy passes out in the surf.</p>
                        <p>Dave! (39:27.764)
                        Yeah.</p>
                        <p>I mean, I so anyone who&#39;s ever taken a writing course would probably know, like, one of the things that you&#39;re really told to do is if you&#39;re going to write something, you got to get them in like the first couple of lines. This movie gets you in the first fucking three minutes because this scene, this scene is terrifying, like.</p>
                        <p>Bryan! (39:51.057)
                        hits the ground running.</p>
                        <p>Dave! (39:55.442)
                        You can. So the noises she is making when she first gets grabbed, it&#39;s very subtle. And I think that is sort of like that&#39;s kind of what it looks like when someone does get bitten by a shark, because you don&#39;t know what happened and it&#39;s weird and they just kind of bites you and go away.</p>
                        <p>Bryan! (40:06.48)
                        Yeah, because it doesn&#39;t just go, ah, you know, like they fuck with you a little bit because they want to see what you&#39;re going to do and adjust the strategy accordingly. So yeah.</p>
                        <p>Dave! (40:17.226)
                        And so she kind of lurches like it&#39;s, you know, and so there&#39;s that weird kind of inherently sexual shot at the beginning, what comes up from the below that is filmed very sexually in a weird way. But then she kind of gets like yanked down and it&#39;s very startling. And then it starts dragging her around and you can, the noises she&#39;s making, she screams, Oh God, it hurts. Which it&#39;s just that line is like, it&#39;s so real.</p>
                        <p>Bryan! (40:25.885)
                        Yeah.</p>
                        <p>Mm-hmm.</p>
                        <p>Bryan! (40:39.064)
                        It hurts I hate that Yeah, yeah No, no, I hate that because of the way that it makes me feel because it&#39;s It feels very relatable because like I think you if in such a</p>
                        <p>Dave! (40:44.49)
                        I mean, I don&#39;t know if you hate it because you think it&#39;s dumb, but I think that shit is scary.</p>
                        <p>It feels very real.</p>
                        <p>Uh...</p>
                        <p>Bryan! (41:00.696)
                        a situation where you don&#39;t, you can&#39;t see, that&#39;s one of the reasons I don&#39;t go in the water. I don&#39;t, we live, I live on the fucking seacoast. We grew up by the ocean. And like, I did not, I do, I&#39;ve not, I haven&#39;t been in the ocean in decades. Like, I do not trust the ocean because when it&#39;s a person goes in there, you are shot to the bottom of the fucking food chain. You can&#39;t see it and our bodies are not equipped to acclimate to that environment. So.</p>
                        <p>She has no idea what&#39;s happening to her. We do simply because we&#39;re watching a movie called Jaws that has a shark on the poster, but we don&#39;t see the shark until about an hour into the movie. So she&#39;s reacting to what we basically we know is a shark, but we don&#39;t know what this looks like. We have no idea. No, no, no. I mean, I don&#39;t think any of it is. I mean, the shark chases them and like drags the ship around later on. Like it&#39;s not, this is not shark behavior.</p>
                        <p>Dave! (41:43.822)
                        Now, and this is not actually what a shark would do, but it...</p>
                        <p>And then, but like she gets to that kind of buoy or whatever that mile marker, channel marker is, and you feel like, okay, she&#39;s safe because there&#39;s that moment of like sort of reprieve. And then it just grabs her again and you go right back into it. And apparently she like dislocated her hip because she&#39;s in a, like a contraption that&#39;s whipping her around underwater. And she either she dislocated her hip or her shoulder. Like she is hurt very badly in this scene. So who knows all that shit could be genuine, but.</p>
                        <p>Bryan! (41:55.684)
                        She gets to the buoy and...</p>
                        <p>Yeah, yeah. Yeah.</p>
                        <p>Bryan! (42:18.696)
                        Yeah.</p>
                        <p>Dave! (42:21.602)
                        There&#39;s something so realistic about it. It&#39;s like, you know, how many times have you actually hurt yourself and you actually say ow? You say it a lot, actually. And I think that&#39;s why she&#39;s not just like shrieking. She&#39;s saying, oh God, it hurts and it&#39;s very upsetting. Also, this movie is PG, y&#39;all.</p>
                        <p>Bryan! (42:30.992)
                        Yeah.</p>
                        <p>Yeah, yeah.</p>
                        <p>Bryan! (42:43.868)
                        Which is crazy because I have to constantly remind myself that and we talked about this a little bit, just in texts, but people show this movie to their kids. And I remember when we were like, when we were first go to like video paradise, in Salem and we would get movies and be like, oh, well, why don&#39;t we adjust? It&#39;s only PG. Cause apparently mom and dad wouldn&#39;t get us our rated movies, obviously. And they would be like, oh no, that&#39;s a very bloody movie.</p>
                        <p>And I was like, what? But then having, it is, and I was even taken by it because like I just saw it like last year and I always forget like there are these huge blooms of blood, there are severed body parts. There&#39;s like, it&#39;s crazy how violent this movie is. Yeah, yeah, it really is. It very much is a slasher movie in the same way that fucking.</p>
                        <p>Dave! (43:16.65)
                        And it kind of is a very bloody movie.</p>
                        <p>This is a slasher film.</p>
                        <p>Bryan! (43:42.744)
                        Black Christmas was like a year before, like it&#39;s a body count movie.</p>
                        <p>Dave! (43:46.398)
                        You mean the other perfect movie? I&#39;ll say that. I mean, I like that movie a lot.</p>
                        <p>Bryan! (43:48.412)
                        The other perfect movie. Yeah. Oh, I do too. I do too. I don&#39;t know if I&#39;d call it perfect, but I enjoy it a great deal. All right. We&#39;ll get there. So we cut to the morning and recent city transplant, Martin Brody is waking up. He&#39;s doing some real Simpsons grades, New England accent jokes. Pac the car, which I hate that shit.</p>
                        <p>Dave! (43:55.183)
                        No, you&#39;re wrong. You&#39;re wrong about that.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (44:15.74)
                        But, um, one of the Brody sons comes in.</p>
                        <p>Dave! (44:17.328)
                        Yeah, as a Massachusetts, I find a lot of this a little offensive.</p>
                        <p>Bryan! (44:22.549)
                        Yep, one of Brody&#39;s sons comes in with blood on his hand, declaring that he got hit by a vampire.</p>
                        <p>Dave! (44:28.65)
                        Yeah, this kid is the most fucking 70s kid I&#39;ve ever seen in anything ever.</p>
                        <p>Bryan! (44:31.76)
                        He I love it him and his brother. So I got a whole aside here about Spielberg&#39;s portrayal of children in movies. I fucking love this about his movies. He does it well into the 80s. But it&#39;s jaws in close encounters in particular ET. And also you can kind of see his influence on poltergeist with the children and that one. But</p>
                        <p>Dave! (44:56.726)
                        Because you get the feeling he genuinely remembers what it&#39;s like to be a child.</p>
                        <p>Bryan! (45:01.48)
                        These kids are maniacs.</p>
                        <p>Dave! (45:05.07)
                        I mean, this kid, I don&#39;t know, this kid&#39;s a bit of a waste of time and he always is like weirdly out of breath. I don&#39;t know why. The other kid though, the other kid is the fucking star of this movie. I love that kid.</p>
                        <p>Bryan! (45:12.794)
                        I know.</p>
                        <p>Although the little brother. Yeah, he&#39;s great because they got that whole scene where they have that thing where he&#39;s like mimicking what his dad does the table. I love that seat, but they&#39;re always like feral fucking maniacs who occupy the background. There&#39;s ever. There&#39;s hardly ever a big piece of the movie that they&#39;re really a piece of but it&#39;s just this disorder of society that just exists on the margin of Spielberg movies.</p>
                        <p>Dave! (45:19.181)
                        Yeah.</p>
                        <p>Brilliant.</p>
                        <p>Bryan! (45:47.348)
                        Close Encounters where one of the kids at Dreyfus&#39;s sons climbs into a playpen where he finds like a baby doll and just smashes it repeatedly against the railing while adults talk and just ignore him. It&#39;s fucking nuts. It&#39;s a weird detail that appears in all of his movies that I love up to a point. He stops doing it eventually.</p>
                        <p>Dave! (46:00.238)
                        I mean, he in E.T. the whole thing is shot from a child&#39;s perspective, the entire movie.</p>
                        <p>Bryan! (46:13.64)
                        Mm-hmm. Oh shit like when they first when they first really introduced the children in ET the scene is Mayhem they&#39;re playing Dungeons and Dragons They&#39;re listening to the Jim Carrell band and one of the kids has a smoldering ashtray next to him Presumably this kid is smoking Cigarettes and Jim Carrell</p>
                        <p>Dave! (46:29.322)
                        You know what kids like? Like junkie post-punk poets and Marlboro ultralights.</p>
                        <p>I mean, until like five years ago, that was what I was into as well, so... I still love one of them. Ah, fuck it, I still love both of them, but...</p>
                        <p>Bryan! (46:46.58)
                        Yeah. can&#39;t give up that Jim Carroll</p>
                        <p>Dave! (46:52.944)
                        Um</p>
                        <p>But so the interesting thing about this scene is this is where you get introduced to that. The way that he uses a soundscape, which is, I think, critically important to pretty much all of this movie, is that while he answers the phone, because the phone rings and he goes to answer it and it&#39;s presumably a deputy or whatever. And you can still hear is it is it Lorraine Gary said her name? You can still hear her, you know, his wife and the kid having the conversation behind him.</p>
                        <p>Bryan! (47:12.738)
                        Yeah. Yes. Yeah, it happens. It happens several times in this.</p>
                        <p>Dave! (47:25.126)
                        at almost the same volume as the conversation he&#39;s having. Now, what I think that really does for this movie, because that is consistent throughout this. You never lose that kind of cacophony until one moment that is very important. But you never lose that, and it keeps the fact that this is a movie about people, you can never get away from that. Like, this is always about, like, shit is going on everywhere you go. So,</p>
                        <p>Bryan! (47:41.952)
                        Yeah.</p>
                        <p>Dave! (47:54.05)
                        You can&#39;t lose sight of the fact that like, this is a world that&#39;s happening because that becomes important later when we get to like the shit about not shutting down the beaches and everything. It&#39;s like, you are never allowed to get away from the fact that like, these are real people. There is a world happening around them that is very important to this whole story.</p>
                        <p>Bryan! (48:10.235)
                        Yeah. Also, I think that there&#39;s sort of a comedic element to it that sort of adds a little levity to scenes where it&#39;s just people talking over one another while somebody kind of ignores them. It happens in several scenes and it&#39;s also a thing that Spielberg does a bunch in his movies and it&#39;s another little like sort of stylistic touch that I like.</p>
                        <p>Dave! (48:31.798)
                        But I think it reminds you like there are stakes to everything we&#39;re doing. There&#39;s a world around us all the time, whether it&#39;s funny or chaotic or frightening or whatever, it&#39;s always present, which reminds you like you&#39;re not alone in any of this.</p>
                        <p>Bryan! (48:33.78)
                        the fence. Right I mean there&#39;s always kids karate in the fences nearby. So we learned that Brody is the new chief of police on Amity Island which is an imaginary island village off the coast of Connecticut.</p>
                        <p>Dave! (48:47.242)
                        is we&#39;re karate-ing the picket fences. Oh, Polly.</p>
                        <p>Bryan! (48:59.532)
                        Amity of the Benchley novel is actually a seaside town on Long Island. That&#39;s sort of near the Hamptons, I guess. Brody meets up on the shore with a kid who passed out. The girls&#39; remains have washed up on the shore. Testament to Spielberg&#39;s ability. Much is suggested of what is left of the girl through reactions and facial expressions. We&#39;re shown at...</p>
                        <p>Dave! (49:19.658)
                        You also, this is where you get the first kind of another very important element of the pic, the whole story is when the kid says, I don&#39;t know what he says, he says something like, oh, I&#39;m an islander. And then he says, Brody, are you an islander? And it&#39;s like, this is, you get that introduction of this idea of insiders and outsiders.</p>
                        <p>Bryan! (49:31.684)
                        Yeah. There is. Yeah, because there&#39;s a late motif to that because that happens again with Brody&#39;s wife on the beach where she&#39;s like so are we islanders and they&#39;re like fuck no you&#39;re not islanders.</p>
                        <p>Dave! (49:45.762)
                        Well, because a big part of this story is, can this outsider solve our problems if this outsider doesn&#39;t even understand who we are?</p>
                        <p>Bryan! (49:53.784)
                        Yeah, yeah. So we are basically we&#39;re basically we see everybody&#39;s reaction to it, which is horror. They have never seen anything like this before. And really all we&#39;re shown is the girl&#39;s hand with like crabs crawling all over it.</p>
                        <p>Dave! (50:11.486)
                        and the guy puking with a whistle in his mouth.</p>
                        <p>Bryan! (50:22.612)
                        you know, um, .. Yep, it&#39;s a perfect account. Yeah, they&#39;re showing, not telling. In any other movie, there&#39;d be a scene, some medical examiner who&#39;s proved prison pre-exam- explains to us precisely what happened, but Spielberg uses photography, he uses scenery and action, just sort of get it all out there. It&#39;s a movie called Jaws. There&#39;s a shark on the poster, like I said. Brody enters shark attack</p>
                        <p>Dave! (50:24.046)
                        also implies this shark is pretty choosy. He only wants some parts, but not all parts.</p>
                        <p>Bryan! (50:52.252)
                        This is all like, this explains so much about what just happened in like 10 seconds. Meanwhile, and we just talked about this chaos happening all around the margins, because there&#39;s like two people talking to Brody at the same time while he&#39;s trying to sort of process this pretty significant problem that&#39;s about to happen to Amity. He&#39;s got people, he&#39;s got one guy yacking in his ear. He&#39;s got...</p>
                        <p>the lady who like answers phones at the police station talking about kids karateing the picket fences. Yeah. Yeah, he uh his plan is very reasonable. Shut down the beaches, but word travels fast. Mayor Vaughn catches up to him and immediately shuts this down, citing tourism dollars being way more important than-</p>
                        <p>Dave! (51:22.078)
                        I mean, can any of these fucking people just solve their own goddamn problems? Like going anywhere with Sheriff Brody&#39;s, like going out with Brad Pitt. Everyone&#39;s got a fucking question for him.</p>
                        <p>In this in this weird moment where he&#39;s like strong arms him on the ferry. Like they hold the ferry for him so these guys can get out and sort of be like, real nice sheriff job you got there. Be ashamed something happened to it. Like as soon as they&#39;re done doing what they&#39;re doing, where they kind of tell him how it&#39;s going to be, they just take the ferry back so that guys can get off.</p>
                        <p>Bryan! (51:50.069)
                        Yeah, he like corners him. Yeah, because. Yeah, yeah, and he doesn&#39;t he doesn&#39;t get where he&#39;s going.</p>
                        <p>Dave! (52:12.79)
                        These because these fucking cowards are like, you know, the one guy is the medical examiner or whatever. And he&#39;s just like, well, I guess I was wrong. We&#39;re going to have to change the paperwork. And it&#39;s like, God, fucking Republicans.</p>
                        <p>Bryan! (52:29.701)
                        I think they call them the town fathers is really what they&#39;re called these guys are so fucking annoying and it&#39;s just like well I guess we&#39;re just gonna see what happens because like the whole the whole thing is no you&#39;re not shutting down the</p>
                        <p>Beach like we need that 4th of July is coming up.</p>
                        <p>Dave! (52:46.975)
                        But this is so this is where this movie gets really interesting. I think it gets more interesting as an adult, because as a kid, you don&#39;t really get what&#39;s going on in the background. As an adult, you can look at it and say, well, yeah, I mean, like we grew up in basically a tourist town. Like, what do you do? You can&#39;t shut this shit down. This is what people live on. Like.</p>
                        <p>Bryan! (52:55.952)
                        Yeah, yeah.</p>
                        <p>Oh yeah, like I think if they if this had ever happened at Hampton Beach, I think a weekend a week would have ruined that town&#39;s economy completely for the rest of the year people like probably wouldn&#39;t have come back from it you know like half the fucking beach would have had to been you know mortgaged and shit like it&#39;s</p>
                        <p>Dave! (53:26.146)
                        Yeah, so as like they become less villainous and you see it much later in the film where he is like, he thinks he&#39;s doing the right thing. And later on he says, like, I thought I was doing what was right. And he really genuinely believes like, well, you know, it&#39;s a risk benefit analysis. One person dies. I mean, people die from shark attacks like once a year or so around here. And so it&#39;s like, it doesn&#39;t seem that odd that like you wouldn&#39;t shut down an entire.</p>
                        <p>Bryan! (53:49.268)
                        Yeah.</p>
                        <p>Dave! (53:54.198)
                        beach economy because one person dies.</p>
                        <p>Bryan! (53:56.836)
                        Yeah, yeah, I mean I suppose like the math of it really does make sense in the context of the movie though Vaughn is a Craven individual, you know, he&#39;s Yeah, yeah</p>
                        <p>Dave! (54:04.458)
                        He does come across that way, but I think as an adult, I see him less like that because he sort of weighs it all and it&#39;s just like, look, I think you&#39;re being a little unreasonable. And you know, at first it&#39;s like, okay, maybe he is being a little unreasonable. It&#39;s not that wild to be like, you can&#39;t shut everything down. Like it&#39;s, now of course that, the scene that happens next, it&#39;s like, okay, I think it&#39;s time to shut this shit down. A dog got eaten. That&#39;s where I&#39;m at with it, but.</p>
                        <p>Bryan! (54:27.413)
                        Yeah, so we cut to it. Yeah, we cut to a busy beach scene. Everybody&#39;s hanging out shitloads of people in the water. Really? I did not notice that. Yeah. Okay, yeah, Brody is watching from the shores. He&#39;s hyper vigilant. Everything looks like a shark to him. And there&#39;s lots of little moments where like</p>
                        <p>Dave! (54:34.55)
                        You get a sick Olivia Newton John needle drop. Yeah, it&#39;s in the background. It&#39;s hard to hear. It&#39;s like it&#39;s diagetic. It&#39;s playing on someone&#39;s radio.</p>
                        <p>Bryan! (54:54.672)
                        You know, somebody screams in the water and Brody like jumps up, but it&#39;s just, you know, people fucking around. And then like, again.</p>
                        <p>Dave! (54:58.978)
                        But the weird thing in that moment, there&#39;s that dude who&#39;s like crouched down in front of him and he&#39;s talking to him and he&#39;s like, ah, I&#39;m a sheriff and my people move in my cars and whatever the fucking whatever, you know, white guys in the seventies say. And, but he doesn&#39;t even react when Brody like, so clearly Brody&#39;s not listening to him. Like if this dude was paying any attention, he would realize the guy&#39;s not listening to a word he&#39;s saying. He doesn&#39;t even react when he jumps up in a panic to look beyond this.</p>
                        <p>Bryan! (55:07.704)
                        Yeah, yeah, something about like parking in front of his house or some shit. Yeah, just blabbing away. Yeah.</p>
                        <p>Dave! (55:28.802)
                        fucking schlub in front of him. The guy just keeps rambling on.</p>
                        <p>Bryan! (55:35.616)
                        So a kid named Alex Kintner goes out to this. The dog yeah yeah. I know justice for Pippet is a dog is a guy who&#39;s throwing stick out to out to the out to see the dog goes and goes again. Yeah, he&#39;s a good boy. Pour one out.</p>
                        <p>Dave! (55:38.801)
                        Well, first we see Pippet jump in the water, and my note just says Pippet no.</p>
                        <p>He&#39;s a good actor.</p>
                        <p>Dave! (55:55.678)
                        I mean, I care a lot more about Pippit than I do about fucking Alec Kittner. I don&#39;t give a shit about that kid.</p>
                        <p>Bryan! (56:00.916)
                        out skinner goes out to sea on his float in the midst of all the splashing chaos we find out that the dog is missing we get some more under the sea footage of the shark stocking and then we see a kind of like a quick it&#39;s a weird it&#39;s very weird scene how it happens we see a very quick flash of like people in the water and then fins kind of turning over as the as the kid goes under with</p>
                        <p>Dave! (56:26.695)
                        And for a second you think, well no, they wouldn&#39;t kill a kid because this is 1975. You don&#39;t kill, I mean, we don&#39;t kill children now in movies. I think they should, but we don&#39;t.</p>
                        <p>Bryan! (56:32.746)
                        No, and then, oh no, and then we get an explosion of gore in the middle of all the kids playing. It is a bloom of blood, like it&#39;s huge. It&#39;s, it&#39;s like it&#39;s, it&#39;s like Johnny Depp&#39;s death scene in Nightmare on Elm Street. Yeah. But it&#39;s like five times the amount of blood, you know, coming to you there. It&#39;s nuts.</p>
                        <p>Dave! (56:38.554)
                        a fucking geyser of blood. Like, that is physically impossible, what happens there. But my God. It really is like.</p>
                        <p>But it&#39;s not, it&#39;s still a background thing. Like the whole thrashing, just the way this scene is shot is fucking brilliant because again, it&#39;s that idea of like, there&#39;s a world happening around you. There&#39;s people playing in the water and kids and all this shit. And you just sort of see in the background this weird thrashing around. Then you see the explosion of blood, but there&#39;s still all around him up until that moment. People just being in the water.</p>
                        <p>Bryan! (57:22.968)
                        Yeah, and then the way that they really drill into the central focal point of this scene where it goes to Brody is a fucking awesome dolly zoom on him. Yeah, oh yeah, once they did that everybody started doing it. It&#39;s a fucking awesome camera move. Yeah. Oh, they do it in...</p>
                        <p>Dave! (57:33.202)
                        And that&#39;s ripped off from that is ripped off from Hitchcock. It&#39;s from Vertigo.</p>
                        <p>Like he does it here, John Carpenter tries to rip it off in someone is watching.</p>
                        <p>Bryan! (57:48.432)
                        They do it in Invaders from Mars also. Like it&#39;s a very, it&#39;s a ubiquitous try. I think that Hitchcock was the first person to do it. Like his camera, his cameramen were the people who were like, okay, if we focus like this while we&#39;re moving the dolly at the same time, you get this... Yeah.</p>
                        <p>Dave! (57:56.247)
                        He was.</p>
                        <p>Yeah, it&#39;s essentially zooming in and panning back at the same time. So it gives that deeply disorienting feeling.</p>
                        <p>Bryan! (58:11.448)
                        It&#39;s such a cool thing. Everybody&#39;s seen the scene. It&#39;s one of the sort of like, it&#39;s one of like a dozen iconic moments of this movie. But, right, cause after that we cut to mayhem on the beach. Like everybody is running out of the, everybody is running out of the water. Adults are like shoving kids off of their floats and like swimming to shore on them.</p>
                        <p>Dave! (58:18.422)
                        And it&#39;s the moment where you&#39;re supposed to be like, oh shit, this is not just a normal shark.</p>
                        <p>That actually happens later.</p>
                        <p>Bryan! (58:38.536)
                        Oh, OK. Yeah, but all that remains of Alex is blood on the surf and his torn float. Yep. Yeah, and as this is happening, we learned basically just through body language that Brody does not like the water. This is going to be a problem for him.</p>
                        <p>Dave! (58:44.466)
                        And like everyone is grabbing their kids and there&#39;s fucking his 72 year old mother just forlorn on the shore yelling for her son. It is a it is a moment.</p>
                        <p>Well, he says the guy says to him, we&#39;ve heard about you, Chief Brody, you don&#39;t like the water, do you? I&#39;ll tell everybody who does not live in Massachusetts with me, people do not talk like that here anymore. They used to, but they do not anymore. Maybe if you go to the South Shore, but in the South Shore, you get a little bit more like this. Like it&#39;s a lot of fucking this and fucking that.</p>
                        <p>Bryan! (59:11.222)
                        Yeah, yeah. No. It&#39;s it&#39;s. It&#39;s door. It&#39;s it&#39;s Dorchester. Really, it&#39;s. No.</p>
                        <p>Dave! (59:30.73)
                        Yeah, it&#39;s a lot of that. It&#39;s not the mid-Atlantic chowder.</p>
                        <p>Bryan! (59:37.172)
                        Chowdah! Say it! Uh, Chowdah! Err... .. So at a town meeting we find out that nobody wants to take seriously the shark problem. Brody... Mmhmm. Yeah. Yeah. Probably.</p>
                        <p>Dave! (59:38.859)
                        Hey</p>
                        <p>Ich bin ein Springfielder.</p>
                        <p>Dave! (59:52.886)
                        But again, you get this moment of like, you hear all these people talking and there&#39;s that lady and these are all, I think for the most part, these are all local hire people. And a lot of them, though, they definitely are. I know they are. But the woman, the woman you hear a lot, the woman who sat on the beach, who was just like, well, if you&#39;re not from here, you&#39;re not going to be. She is definitely a local hire. But you get those people that are just like, well, you can&#39;t shut down the thing.</p>
                        <p>Bryan! (01:00:06.968)
                        Oh yeah, I mean economically.</p>
                        <p>Yeah. Uh huh, yeah. Yeah, yeah, and Brody. I don&#39;t you got it. You got to give it a grace period. Then it&#39;s funny after a few days.</p>
                        <p>Dave! (01:00:19.39)
                        You know, it feels very genuine. And then you get that fucking guy who&#39;s just like makes some joke. And it&#39;s like, dude, a child was just eaten. At least don&#39;t make jokes about it 20 minutes later. Do what I do and wait a couple of days.</p>
                        <p>Bryan! (01:00:41.5)
                        But Brody in this scene is desperately trying to navigate a delicate scenario, where nobody wants to close the beaches, but he has to. And it&#39;s a smart thing to do at this point. Vaughn assures everybody it&#39;s only for 24 hours, which is not what Brody agreed to. And also.</p>
                        <p>Dave! (01:00:57.994)
                        Yeah, he immediately under- like undermines Brody entirely. It&#39;s just like, well, just for 24 hours. And Brody&#39;s like, well, I didn&#39;t agree to that.</p>
                        <p>Bryan! (01:01:04.624)
                        Yeah, yeah, the local business owners basically react like there&#39;s a, I don&#39;t know, a deadly global pandemic going on. But this is where enter Quint.</p>
                        <p>Dave! (01:01:15.234)
                        Yeah, enter walking sea shanty quint.</p>
                        <p>Bryan! (01:01:18.392)
                        salty local fisherman.</p>
                        <p>Dave! (01:01:20.418)
                        So do you know there was an, this is not the original scene where you, the introductory scene, there is a deleted scene. You&#39;ve ever seen it? So in the deleted scene, it&#39;s very much like the scene where we&#39;re introduced to Robert Mitchum in Cape Fear in the theater where he goes. So Quintus is at like a theater watching Moby Dick, the fuck is this?</p>
                        <p>Bryan! (01:01:26.676)
                        Yeah, I&#39;ve never seen it. No.</p>
                        <p>Oh, yeah.</p>
                        <p>Bryan! (01:01:46.828)
                        Yeah, it&#39;s a little Gregory Peck Yeah Yeah, you find I mean we definitely find out it&#39;s pretty fucking crazy</p>
                        <p>Dave! (01:01:49.854)
                        Yes, so Gregory Peck, he&#39;s watching that and he&#39;s like laughing and being really like loud and obnoxious and I think he&#39;s like walking around the theater like yelling like really like annoying and people are getting up and leaving and you&#39;re supposed to sort of get like this guy&#39;s an unh- like he&#39;s a little over the top as it is. But apparently, apparently Gregory Peck hates his performance in that movie so much that he refused to, because he owns the rights to it.</p>
                        <p>And he refused to let them use it because he was like, I do not want people to see my terrible acting. It&#39;s like Gregory Peck, lighten the fuck up.</p>
                        <p>Bryan! (01:02:18.306)
                        Oh.</p>
                        <p>Oh wow. Also, it sounds a little heavy handed because what we get, what we get is very clearly an insert shot. But it&#39;s, it&#39;s way better. It&#39;s more concise. And it really introduces you to the character in the state that they really want you to know him at this point that he&#39;s salty, you know, see Simpson sea captain type guy. He&#39;s even</p>
                        <p>Dave! (01:02:28.476)
                        Oh yeah.</p>
                        <p>I like to think that Robert Schaaf showed up with a cracker and he was like, Hey, hey, I got, don&#39;t worry. I got this. Let me do this. I&#39;m going to do, I&#39;m going to do this. I&#39;ll go, I&#39;ll say, I&#39;ll say when to cut. Let me do this. And he&#39;s got a cracker and he&#39;s like, he just brought the cracker from off so he&#39;s only got one cracker. Like where are the rest of the crackers, man?</p>
                        <p>Bryan! (01:02:57.983)
                        Yeah. Yeah, that&#39;s right. I know he&#39;s like, he&#39;s like, all right, Steve, make sure you get this because I&#39;ve only got one cracker.</p>
                        <p>Dave! (01:03:09.334)
                        Don&#39;t call cut. I&#39;ll call cut. Don&#39;t call cut. And he&#39;s just fucking chomping, chomping on the cracker. It&#39;s like gathering at the corner of his mouth. Do you know who Quint is inspired by? So he&#39;s kind of a composite character. There are two. The character in the book is based on some guy who&#39;s like a who was a fisherman in Montauk. Um.</p>
                        <p>Bryan! (01:03:18.096)
                        Yeah.</p>
                        <p>Yeah, I knew about that.</p>
                        <p>Dave! (01:03:35.606)
                        And he was kind of wild, but the character in the movie is based on the guy who plays Ben Gardner, who is like a local, like a crusty local character. And yeah, like apparently this dude was just like he&#39;s lived there forever and would just sort of like tell wild tales and he never wore shoes anywhere. He would like when you get drunk and like drive a herd of cattle around the island, like he was just a wild character and he is based mostly like the performance is based mostly on.</p>
                        <p>Bryan! (01:03:45.802)
                        No kidding.</p>
                        <p>Hmm.</p>
                        <p>Bryan! (01:04:05.32)
                        That&#39;s funny.</p>
                        <p>Dave! (01:04:06.154)
                        You know, he&#39;s only in the movie for like a couple of scenes. You see him, he&#39;s in one scene very prominent.</p>
                        <p>Bryan! (01:04:11.216)
                        Yeah, yeah, when they, and he dies. Yep. So we go back to Brody&#39;s home. Brody&#39;s son is sitting in his birthday present, which is a small bike, small boat. It&#39;s tied up, sitting in the boat, sitting in the boat, tied up to the jetty. Yep. And this is more of that sort of Spielberg kind of juvenile chaos. And the kids have the, the kids have crazy accents while the parents have none.</p>
                        <p>Dave! (01:04:13.462)
                        and his head falls out of the boat.</p>
                        <p>Just sitting in jetty, time to the boat.</p>
                        <p>Dave! (01:04:36.534)
                        Yeah, they may as well have been in Sleepaway Camp. This is the most Long Island shit you&#39;ve ever heard.</p>
                        <p>Bryan! (01:04:40.54)
                        They&#39;re like those, I know, or they&#39;re actually, yeah, cause this is around Long Island, but yeah, like it&#39;s, it&#39;s very thick. They&#39;re like the kids in fucking maniac that the, you know, I&#39;m not supposed to leave the park, but yeah, everybody is very paranoid at this point. Like one girl&#39;s an accident, adding a little boy to it is, you know, presents a real problem.</p>
                        <p>Dave! (01:05:06.39)
                        Yeah, but like, why do they take Quint seriously? This guy&#39;s a fucking lunatic from the moment he&#39;s spitting crackers all over you. Like, is he your only option? This is an island full of fishermen.</p>
                        <p>Bryan! (01:05:13.729)
                        They don&#39;t exactly though. They well as we find out like he&#39;s got a specialty but also killing sharks is kind of his bag. He uh yeah the whole yeah you know I make a living I&#39;ll do it for ten thousand dollars he says and everybody&#39;s like</p>
                        <p>Dave! (01:05:22.806)
                        Yeah, spittin&#39; crackers all over you and being crazy.</p>
                        <p>Yep, y&#39;all know me. Oh, I make a living.</p>
                        <p>Dave! (01:05:35.638)
                        Now find him for- find him for three thousand. Well what do you do when you find him? Just point him out? I mean... He&#39;s not gonna stay there.</p>
                        <p>Bryan! (01:05:40.412)
                        take a picture there he is there he is yeah but uh yeah so a couple of local fishermen go uh go out onto the wood there like a nearby dock set lures for the shark thinking that they&#39;re going to catch it themselves uh and they end up getting more than they bargained for when the shark takes the bait drags the entire dock out to sea taking one of the fishermen with him yep and this is this is</p>
                        <p>Dave! (01:06:05.314)
                        Swim, Charlie. Swim. Don&#39;t look back. Don&#39;t look back, Charlie.</p>
                        <p>Bryan! (01:06:09.868)
                        This is another awesome like Spielberg touch because you don&#39;t see the shark, but you can tell where it is because it turns to chase the fisherman who&#39;s trying to swim back to shore. You can see its position based on where the dock is because it&#39;s dragging this dock around with it.</p>
                        <p>Dave! (01:06:25.358)
                        And this is when you really understand like, okay, this is not just a regular shark. Like this thing is, now this part is actually based on a real thing. So in the New Jersey cases, and like it&#39;s like in 1913 or something, there was one of the things that happened was, so in New Jersey, a bull shark got into like an estuary and he kind of got stuck there. Like he was stuck in this.</p>
                        <p>Bryan! (01:06:30.358)
                        Yeah, it&#39;s a big deal. Oh no shit, I didn&#39;t know that.</p>
                        <p>Oh yeah.</p>
                        <p>Bryan! (01:06:50.962)
                        Yeah.</p>
                        <p>Dave! (01:06:53.806)
                        river pond area. And, you know, kids like a bunch of kids have gone down to the swimming hole, ye olde swimming hole. And they&#39;re, you know, splashing around, you know, kids doing a swimming hole.</p>
                        <p>Bryan! (01:07:01.956)
                        Yeah. Oh yeah, because this is this is happens in the movie essentially.</p>
                        <p>Dave! (01:07:07.794)
                        Well, so the guy there was like a guy who was just like, Oh, you kids having fun and yield this women hold something, something. And one of them gets attacked and like it&#39;s like starting to drag the kid out, because that&#39;s what bull sharks do. They grab you and drag you off. And it starts to do that. And the guy&#39;s like, Holy shit, I got to do something. So he jumps into the water to try to grab the kid. The shark lets go of the kid and swings around and grabs the guy instead. And it&#39;s just like this shark is just like, I will eat.</p>
                        <p>Bryan! (01:07:22.877)
                        Mm-hmm.</p>
                        <p>Damn.</p>
                        <p>Dave! (01:07:37.182)
                        all of you until one of you lets me out of this goddamn pond. And you know what? He did eat a bunch of them.</p>
                        <p>Bryan! (01:07:38.124)
                        Yep.</p>
                        <p>Yeah, yeah.</p>
                        <p>Yeah, he did.</p>
                        <p>Dave! (01:07:47.434)
                        He ate more people than a shark has ever eaten.</p>
                        <p>Bryan! (01:07:51.44)
                        I can&#39;t believe I ate the whole thing. So the next day, a thousand fishermen stroll into town chasing the shark and the $3,000 rewards. And again, and it&#39;s another just scene of Spielberg mayhem. It&#39;s just shitloads of people all over the place. And they all appear to just be like drunk yahoos. There&#39;s even a guy who like Brody stops because he&#39;s going to go out to sea with like a bundle of dynamite. And this is where we enter Matt Hooper, oceanographer.</p>
                        <p>Dave! (01:07:53.303)
                        Yep.</p>
                        <p>Now, I&#39;m going to interject here because this is, I think, what this movie is really about. Is you now have essentially three characters and later on you&#39;ll get more of the three-ness, but you get three very different types of masculinity here. You have you have Quint, who is this sort of the old way of being and that becomes much clearer later, but it&#39;s this sort of old like cowboy John Wayne bullshit. And</p>
                        <p>Bryan! (01:08:36.918)
                        Oh yeah.</p>
                        <p>Yeah.</p>
                        <p>Dave! (01:08:49.302)
                        This is a movie that&#39;s happening in the 70s when all of that shit is changing really hard and really fast. And so on the other end of that spectrum, you have Richard Dreyfuss&#39; character, Matt Hooper, who is essentially an academic, kind of wimpy, nerdy guy who seems kind of ineffective and awkward all the time. And in the middle of those two, you have Brody, who&#39;s something of both of them. And it&#39;s this.</p>
                        <p>Bryan! (01:09:12.028)
                        Yeah. Yep.</p>
                        <p>Dave! (01:09:15.086)
                        push and pull around masculinity and around what is the new man and what kind of man can solve this problem. You get this in the introduction when Dreyfus gets off the boat and he gets up onto the dock and he&#39;s standing in front of this guy and this guy is like a good foot taller than him. And it&#39;s sort of like, here&#39;s the old salty fisherman and here&#39;s the new guy. Who can solve this problem? Because they&#39;re all like, we&#39;re going to go out and fucking brute force this problem.</p>
                        <p>Bryan! (01:09:23.216)
                        Yeah. Ah!</p>
                        <p>Mm-hmm.</p>
                        <p>Bryan! (01:09:38.674)
                        Yeah.</p>
                        <p>Dave! (01:09:44.286)
                        We&#39;re going to solve it that way. And essentially, the movie is going to tell us for the first 45 minutes, you cannot solve this problem that way. Then it&#39;s going to completely go back on what it said later on. But we&#39;ll get there.</p>
                        <p>Bryan! (01:09:55.82)
                        Yeah, yeah, yeah. But Hooper is shown the remains of the first victim. And once again, Spielberg is very careful not to show much more than the severed arm.</p>
                        <p>Dave! (01:10:06.666)
                        My question though is, why is a marine biologist doing an autopsy?</p>
                        <p>Bryan! (01:10:13.628)
                        That&#39;s a good question. I hadn&#39;t really considered that. Yeah, he&#39;s not a medical examiner at all. Yeah, but our reactions are dictated by Hoopers in this one because before everybody was like sickened by what they saw, but Hooper is the first guy who kind of has the context to really communicate to us that this is a much bigger problem than we had really thought it was. He&#39;s professional, uses academic language.</p>
                        <p>Dave! (01:10:15.286)
                        Like, he doesn&#39;t know how to do an autopsy, he&#39;s a marine biologist!</p>
                        <p>Bryan! (01:10:40.396)
                        And even as he&#39;s losing his cool looking at the remains, and he&#39;s shocked that Brody didn&#39;t follow some protocol that he probably didn&#39;t even, he probably wasn&#39;t even aware of. It&#39;s a hell of a way to sort of communicate the scope of terror here. We knew this was a problem, but Hooper was letting us know that it&#39;s even worse than we thought. So.</p>
                        <p>Dave! (01:11:01.901)
                        Right, like sharks are big, but this shark is very big.</p>
                        <p>Bryan! (01:11:05.02)
                        This shark is something else. So the Yahu&#39;s all bring in a shark and everyone is convinced that they caught the shark. I do too. Hooper is not, he&#39;s not convinced. Vaughn is every Republican politician whose city or state was home to yet another mass shooting. This is not the time or the place to confirm that this is the cause of death around here. I just wanna get past this is basically what Vaughn is. But.</p>
                        <p>Dave! (01:11:11.963)
                        I fucking love this scene.</p>
                        <p>Bryan! (01:11:34.468)
                        Everybody is basically feeling pretty good about this. We got it.</p>
                        <p>Dave! (01:11:37.215)
                        And he says, he says, Well, what it&#39;s a. What kind of shark is it? And then Hooper says, It&#39;s a tiger shark. And then the guy says, It&#39;s a wha? I fucking love that part.</p>
                        <p>Bryan! (01:11:46.898)
                        Yeah.</p>
                        <p>Yeah. Yeah, yeah. But in the middle of all of this, everybody goes. Oh, no shit. God damn. No kidding. That&#39;s like fucking Cannibal Holocaust or some shit there.</p>
                        <p>Dave! (01:11:57.374)
                        Also, that is a real dead tiger shark. Yeah, which is sad and gross.</p>
                        <p>Bryan! (01:12:07.472)
                        So Alex Kintner&#39;s mother comes up in front of everybody and just lays the responsibility for her son&#39;s death on Brody, who&#39;s been struggling against all the cowards in town to handle this responsibly. Painful to fucking watch, because he&#39;s... It is. Okay.</p>
                        <p>Dave! (01:12:20.374)
                        Yeah, I&#39;m going to jump back in because this scene is fucking amazing for a couple reasons. First of all, when she slaps him, that is the first time in this movie where all of that kickoff and his sound drops out and everybody stops talking.</p>
                        <p>Bryan! (01:12:36.154)
                        That&#39;s right. Yeah.</p>
                        <p>Dave! (01:12:37.374)
                        It&#39;s almost as though they built all of that soundscape just for this fucking moment, because she walks up in her widow&#39;s weeds and slaps him in the face. And I think for most of my life watching this movie, I was always upset. I mean, obviously this scene is supposed to be upsetting. It&#39;s when you are confronted with the reality of what&#39;s happening. It&#39;s that idea of like, again, these are not just people being killed off. These are people that are dying in this town.</p>
                        <p>Bryan! (01:12:42.692)
                        Yep.</p>
                        <p>Mm-hmm.</p>
                        <p>Dave! (01:13:05.674)
                        You know who these people are because they&#39;re in every fucking scene. Whether they&#39;re just yakking in the background or smacking him in the face, they&#39;re in every moment. They&#39;re a part of this world too. So when he dies, this moment reminds you, this is a person who died. There are real stakes here. So then she smacks him, fucking noise drops out. And as a kid, and probably in my adulthood as well, it always bugged me because it&#39;s like, no man, fucking tell her that it&#39;s not your fault, but the reality is that it is his fault.</p>
                        <p>Bryan! (01:13:09.426)
                        Yeah.</p>
                        <p>I KNOW!</p>
                        <p>Dave! (01:13:35.638)
                        because he won&#39;t stand up to them. And it&#39;s sort of like, he and the reason, like why are you just standing there and letting this woman blame you for something? It&#39;s like, he&#39;s being blamed because he should be blamed because he won&#39;t do anything about it. Because what she&#39;s essentially saying is, you knew this, you knew that this was, there was a danger and my son is dead. And the reality of that is, he did know that and he didn&#39;t do anything. And that&#39;s why she says, my son is dead. I just wanted you to know that. And it&#39;s like,</p>
                        <p>Bryan! (01:13:48.264)
                        Yeah.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:14:03.996)
                        Yeah, yeah. Oh, I know. And that&#39;s the thing is Brody, Brody is, until the very end of the movie, he is mostly ineffective at everything. He shrinks before the sort of influence of the town fathers. He knows what he needs to do, but he never fucking does it. When he finally gets out on the water, he&#39;s terrified of the water, you know.</p>
                        <p>Dave! (01:14:05.366)
                        Fucking A, man!</p>
                        <p>Bryan! (01:14:29.013)
                        Everything about him is just, he doesn&#39;t really do it. He&#39;s like fucking Indiana Jones in Raiders.</p>
                        <p>Dave! (01:14:33.802)
                        But that&#39;s why he&#39;s the middle man, because, you know, Shaw represents the old world, the old gross, toxic masculinity. And, you know, Hooper, he&#39;s sort of the other end of the extreme. Like, he knows exactly what he is and who he is. And he opens his mouth. He is me, essentially. He knows that no one&#39;s going to like what he has to say. And he probably should wait before he says it. But he says it anyway. And, like, he gets that. Brody&#39;s in the middle where it&#39;s like, what are you?</p>
                        <p>Bryan! (01:14:57.565)
                        Yeah, yeah.</p>
                        <p>Dave! (01:15:02.07)
                        He doesn&#39;t know what he is and what kind of man he is. He&#39;s the new man in America. And that what does that, what is that? And then can this man solve this crisis?</p>
                        <p>Bryan! (01:15:07.356)
                        Yeah.</p>
                        <p>Yeah, that&#39;s a good point. Because yeah, this does kind of come on the back of Vietnam. And guys were coming back way different. It transformed the way that. Yeah, yeah. It really, I mean, the 70s was crazy revolutionary in ways I don&#39;t think anybody was really expecting.</p>
                        <p>Dave! (01:15:25.598)
                        and fucking shattered.</p>
                        <p>Also, that woman, local hire. She was a local acting teacher. And she is fucking good in that scene.</p>
                        <p>Bryan! (01:15:38.008)
                        Yeah, yeah, I mean it makes... Yeah, it makes... She&#39;s great. Yeah, she really fucking sells you on it. That&#39;s an uncomfortable scene to watch. So now we go back. We have a little bit more sort of home scenes with Hooper. This is the part where he kind of has the kind of mimicry scene with the sun, which is very cute. It&#39;s a very human moment.</p>
                        <p>But Hooper comes over to Brody&#39;s and we find out a little bit of his background, why he studies sharks.</p>
                        <p>Dave! (01:16:10.454)
                        This is also where you get more of that sort of awkwardness where he comes in and he&#39;s like, I brought wine. I didn&#39;t know which kind to bring, so I brought both. And it&#39;s like, you know, he&#39;s that, again, he&#39;s that sort of awkward indecisive, like he didn&#39;t bring whiskey to drink for men. He brought wine and didn&#39;t know what kind, and then he sits down and just starts eating off of one of the plates. But there is that great moment where he sits down and looks at him and he goes, how was your day?</p>
                        <p>Bryan! (01:16:16.528)
                        Yeah, he&#39;s got like... Yeah.</p>
                        <p>Yeah, well, well.</p>
                        <p>Bryan! (01:16:38.768)
                        That&#39;s right. Yeah, but he informs Brody that he doesn&#39;t think it&#39;s the right shark And so they go down to the docks and they cut the shark Right cuz Brody pours the wine pours an entire glass full of wine for himself while everybody else gets like the two fingers of wine Oh, what is it? I think I think Hooper says well after he opens. He&#39;s like, oh, yeah, you want to let it breathe</p>
                        <p>Dave! (01:16:45.666)
                        So they get drunk and go cut open a fish.</p>
                        <p>separate. Okay.</p>
                        <p>Bryan! (01:17:06.376)
                        But yeah, they cut open the shark and Hooper starts throwing its stomach contents out on the floor. We get some fish, there&#39;s a tin can, there&#39;s a Louisiana license plate, but no human remains. So Hooper and Brody go out on the water in...</p>
                        <p>Dave! (01:17:22.094)
                        Because again, nothing like getting drunk and going out on the water.</p>
                        <p>Bryan! (01:17:25.616)
                        Yeah, that&#39;s totally responsible. But they go out. Hooper has an idea for where the shark is hunting. So they go out there. They try to track the shark. They find the remains of one of the local fishermen&#39;s boats. This is Ben Gardner. And it&#39;s been fucked up.</p>
                        <p>Dave! (01:17:39.906)
                        Ben Gardner. Well, you do actually see in the scene where shit&#39;s going wild.</p>
                        <p>Bryan! (01:17:45.691)
                        Yeah, yeah. So Hooper decides to dive and look at the hall. Find some.</p>
                        <p>Dave! (01:17:50.454)
                        Now I don&#39;t get this part. Why does he have to go under? Because he says, let&#39;s just tow it all back. And he&#39;s like, I just got to check the hull. Why do you have to check the hull at night if not for the plot?</p>
                        <p>Bryan! (01:18:02.742)
                        Well, I know that&#39;s exactly what it is. He goes into the water so that we can set up a spooky jump scare scene. And that&#39;s jump scare scared the shit out of me when I was like nine.</p>
                        <p>Dave! (01:18:09.559)
                        And I.</p>
                        <p>I have seen this movie a million fucking times and every single time this scene gets me.</p>
                        <p>Bryan! (01:18:17.444)
                        Yeah, yeah. But what he finds is he finds a huge hole in the ship&#39;s hull and there&#39;s a big, big shark tooth in it. And also, shot glass? Yep. And the jacked up body of the ship&#39;s captain.</p>
                        <p>Dave! (01:18:25.927)
                        Size of a shot glass.</p>
                        <p>Mm-mm. Just the head.</p>
                        <p>Bryan! (01:18:36.304)
                        Just the head? Ah.</p>
                        <p>Dave! (01:18:37.266)
                        It&#39;s just the head because why because I thought to myself, why would a shark eat the body and not the head? Did he spit the head out?</p>
                        <p>Bryan! (01:18:46.384)
                        There&#39;s nothing... Yeah, there&#39;s nothing meaty on it. It&#39;s all bone.</p>
                        <p>Dave! (01:18:51.311)
                        I- the shark ate a fucking license plate. I don&#39;t think it cares about the bone.</p>
                        <p>Bryan! (01:18:55.648)
                        Yeah, but we do find out that the tiger shark is basically a garbage can. It&#39;ll eat anything. So they once again try to tell Vaughn that this is a serious problem. This is a much worse problem than we thought. A great white shark is a very big problem.</p>
                        <p>Dave! (01:19:15.702)
                        And so this is where you again, you get that idea of you too are outsiders. You don&#39;t understand the problems here. You don&#39;t understand what it means to do this. And this is also where Vaughn becomes very villainous.</p>
                        <p>Bryan! (01:19:29.008)
                        Right, because this is the part where they&#39;re having this shouting match in front of the welcome to Amity Island sign. But the sign has now been vandalized with a shark&#39;s fin. And the woman swimming on it now says, ah, a shark next to it. And so they&#39;re like, this is a shark that does not come around these parts. This is an eating, this is a killing machine. This is one of those like.</p>
                        <p>Dave! (01:19:49.61)
                        Which I have to ask, did they, was, is that just for the purposes of the plot or did they actually believe that? Because that is not true. That is a shark that very much comes around these parts.</p>
                        <p>Bryan! (01:20:03.634)
                        Yeah, yeah, I may have I may have injected that myself so Oh</p>
                        <p>Dave! (01:20:06.782)
                        No, they do say that. They imply that like this is not a thing that comes here. It&#39;s like that is a thing that comes here every year. We know that to be true.</p>
                        <p>Bryan! (01:20:12.9)
                        Yeah, it&#39;s one of the deadliest known to mankind. Great whites.</p>
                        <p>Dave! (01:20:19.663)
                        Because he says something on the phone with someone where he&#39;s like, I don&#39;t need to come to Australia to see a great white. I&#39;ve got one right here. It&#39;s like, yeah, we get them right here every year. You should know that.</p>
                        <p>Bryan! (01:20:24.253)
                        That&#39;s right.</p>
                        <p>Yeah, so, yeah, they&#39;re fairly common in coastal waters on all continents except Antarctica, but they typically stick to warmer waters and are mostly found in coastal bands closer to the equator, but they do come north. And like pretty much annually, and mostly due to fucking climate change now, they&#39;re becoming, I know, they&#39;re becoming more and more common in places like Maine.</p>
                        <p>Dave! (01:20:50.518)
                        These fucking jerks.</p>
                        <p>Bryan! (01:20:56.136)
                        where the water is fucking freezing all year round, even in the summer. So...</p>
                        <p>Dave! (01:21:03.606)
                        So don&#39;t go to Maine, that&#39;s the takeaway.</p>
                        <p>Bryan! (01:21:08.155)
                        They don&#39;t usually attack people, but once they do, we kind of shoot to the top of their diet. So, to punctuate the danger of this scene, Spielberg shows us a shot of the Falmouth Ferry sailing into port, packed with people. An absolute buffet for the shark.</p>
                        <p>Dave! (01:21:28.562)
                        I was going to say that is not the that&#39;s not the fairy that goes to the vineyard that&#39;s the fairy that goes to the cape yes</p>
                        <p>Bryan! (01:21:34.772)
                        Oh, really? All right. But it&#39;s a great shot, because it&#39;s just like, these guys are not getting through to him. Vaughn is like, they vandalized our sign. Why don&#39;t you go after this?</p>
                        <p>Dave! (01:21:44.65)
                        Yeah, that&#39;s this moment with the fucking sign. That is very like that feels so relevant now. It&#39;s like the sign is the problem. The vandalism is the problem. It&#39;s like, no, the fucking shark that&#39;s eating people is the problem. You idiot.</p>
                        <p>Bryan! (01:21:58.052)
                        Yeah, and then we immediately cut to the packed fucking ferry coming in. And then just shots upon shots of people like streaming into town for, for 4th of July. Yep. So, um, 4th of July. Yes. Yeah. So the 4th of July weekend begins, we get the busy shots of the beach. Now TV reporter on the shore. And this is Jaws author, Peter Benchley.</p>
                        <p>Dave! (01:22:02.382)
                        to the buffet.</p>
                        <p>They&#39;re delicious looking 70s people.</p>
                        <p>Dave! (01:22:15.734)
                        This is where we get our Peter Benchley cameo as well.</p>
                        <p>Bryan! (01:22:28.939)
                        making the rounds of the people on the beach, begging them to get into the water because nobody&#39;s in the water. The beach is packed, but nobody is in the water.</p>
                        <p>Dave! (01:22:36.298)
                        And that fucking guy where he&#39;s like, you need to go get into the water. No one&#39;s going to. That is the most Massachusetts looking dude I have ever seen.</p>
                        <p>Bryan! (01:22:39.58)
                        He&#39;s like, you need to get in the fu-</p>
                        <p>Yeah, yeah, definitely a South Shore guy But this is this is Yeah, so this he&#39;s begging this guy to get in the water which is kind of a death sentence But he is he is at this I Just put sunscreen man. Yeah, so at this point like Vaughn is the perfect shithead movie bad guy</p>
                        <p>Dave! (01:22:51.544)
                        That guy looks like my landlord.</p>
                        <p>And the guy&#39;s like, yeah, I just put on sunscreen.</p>
                        <p>Bryan! (01:23:13.02)
                        But it works. Everybody goes into the water. Brody&#39;s kid takes his boat into the nearby pond, which is connected to the shore, but is away from a shark&#39;s hunting ground.</p>
                        <p>Dave! (01:23:21.774)
                        And this is again where you get the kid where he&#39;s just like, ah, ah. It&#39;s like, what do you smoke like six packs a day? Oh, it&#39;s the seventies, I guess he could, but Jesus.</p>
                        <p>Bryan! (01:23:25.352)
                        Woody&#39;s in the water or just... Probably did. Yeah, but now out in the water, there&#39;s panic. People start... This is the part where I was talking about earlier, where everybody is rushing to shore. It&#39;s like parents throwing children off of floats so they could swim the float in and stuff. But what it is, is there&#39;s a couple of kids with a fake fin swimming around underwater.</p>
                        <p>Dave! (01:23:52.886)
                        Yeah, and if I thought the son was the most 70s kid, ooh, he just got beat out by these two.</p>
                        <p>Bryan! (01:23:57.052)
                        Those kids. Yep, but notably absent from the scene because it&#39;s shot the same way as the other scenes where the shark is actually stalking. There&#39;s no music in this scene. It&#39;s just silence. The music.</p>
                        <p>Dave! (01:24:11.114)
                        Yeah, Spielberg, shut up! Just let John Williams do his thing, man. Works.</p>
                        <p>Bryan! (01:24:14.256)
                        Yeah, so the music kicks in a few minutes later when a woman sees the shark actually swimming into the pond where Brody&#39;s son is through the estuary. And again.</p>
                        <p>Dave! (01:24:26.346)
                        And that woman has got some real Friday the 13th part three energy. Where she&#39;s just like, oh God, oh, it&#39;s a shark. Oh no. Oh God. Oh God.</p>
                        <p>Bryan! (01:24:30.652)
                        Yeah, I have.</p>
                        <p>Oh no, oh God, oh geez. Yeah, but she&#39;s swimming in, but everyone&#39;s kind of dubious at this prank, but they do eventually sort of move in that direction and they see it. And this is really, this is, we go, we cut over, we see all the boys on the boat. There&#39;s a guy and a dinghy nearby. And this is a little creepy, yeah.</p>
                        <p>Dave! (01:24:59.182)
                        kind of creepy guy, actually. He&#39;s got he&#39;s got a vibe that I don&#39;t know if maybe it just reads differently now than it did then. But like, he&#39;s just like, hey, boys, you having a little trouble? You want some help with your boat? Yeah. Why don&#39;t you come and get in my boat instead?</p>
                        <p>Bryan! (01:25:07.159)
                        Ah yeah.</p>
                        <p>And he&#39;s... Yeah, oh no. He, yeah, he, um, he&#39;s just, you&#39;re right. He&#39;s, he&#39;s rowing out to them in this like shitty little dinghy. They seem to be doing fine, but he&#39;s like, hey.</p>
                        <p>Dave! (01:25:28.834)
                        Yeah, they&#39;re just they&#39;re just tying a sheep shank or doing whatever kids do on the boat. I don&#39;t fucking know. But this dude, he&#39;s just sort of like slowly rowing towards him. I&#39;m like, I don&#39;t think I like this. I don&#39;t I don&#39;t think you should do that, sir.</p>
                        <p>Bryan! (01:25:33.133)
                        Yeah, yeah, but um.</p>
                        <p>No. So the shark knocks over the dinghy near the sun&#39;s boat, and this is the first time that we see the shark. Up to this point it&#39;s just been glimpses of fins and shit, but no, but you see its head obscured by the water and it&#39;s gigantic. The guy, the guy in the dinghy is attacked and his leg is severed amid another thing.</p>
                        <p>Dave! (01:25:52.554)
                        And you still don&#39;t really see it, you kind of see it.</p>
                        <p>Again, did it spit out the leg?</p>
                        <p>Bryan! (01:26:07.876)
                        I know, what the fuck is the deal? Eat the fucking leg, it&#39;s meaty. But um.</p>
                        <p>Dave! (01:26:12.906)
                        I mean, one could argue that&#39;s the best part.</p>
                        <p>Bryan! (01:26:15.36)
                        Yeah, yeah. What is up with the shark? I think the shark just likes to kill. You know, that&#39;s what he&#39;s really into.</p>
                        <p>Dave! (01:26:20.386)
                        It&#39;s a slap. This is the slasher thing. But you also get that moment in this scene where you get that weird fly by camera moment as the shark exits the scene that makes it feel very much like a monster movie where it&#39;s like this feels like Dracula fleeing the scene because it&#39;s sort of it&#39;s I don&#39;t even know how to describe it. It&#39;s probably a crane shot where it just sort of flies by the kid because you get that shot of the kid&#39;s face and he&#39;s clearly in shock</p>
                        <p>Bryan! (01:26:30.983)
                        Yes.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:26:47.94)
                        Yeah, yeah, so those closeups are Like pitch perfect and really selling you on what is happening here</p>
                        <p>Dave! (01:26:49.87)
                        flies away.</p>
                        <p>Right, like this is a monster movie. No fucking bones about it.</p>
                        <p>Bryan! (01:27:02.384)
                        Yep, yep. So Vaughn finally sees the problem up close. We&#39;re in the hospital, sort of treating Brody&#39;s son for shock. And this is when he sort of like, when Brody gets him aside, he&#39;s like, you&#39;re gonna sign this fucking paper so we can get Quint on this and deal with this shark.</p>
                        <p>Dave! (01:27:19.35)
                        But even I see I remembered that scene, him being more forceful. And I swear I watched this movie at least once a year, and he&#39;s not very forceful.</p>
                        <p>Bryan! (01:27:25.648)
                        He&#39;s not, he&#39;s not, I&#39;m exaggerating really, just because I like to do that. But again, yeah, he&#39;s just like.</p>
                        <p>Dave! (01:27:31.858)
                        And this is where you get Vaughn being like, well, no, I thought I was doing the right thing. I didn&#39;t think this was going to happen. And that&#39;s where you had that sympathy again, because it&#39;s like he genuinely does think he&#39;s doing the right thing by trying to save the economy because he is a Republican and puts the economy over everything else. He believes that the economy is OK. Then everything else can be taken care of, even at the expense of a little boy or, you know, some off because before the first Chrissy, the sort of floppy rota looking lady.</p>
                        <p>Bryan! (01:27:43.025)
                        Yeah.</p>
                        <p>Dave! (01:28:03.115)
                        She is a visitor, she&#39;s like a vacationer. So she&#39;s expendable from the very beginning. And then this kid, he is not, he lives there. And so things start to add up and he really does think, well, you know, it&#39;s just a couple, like, you know what? You sell out your own people, do it every day. It&#39;s that idea of like, you know, yeah, it sucks, but like we can&#39;t shut down everything. He thinks he&#39;s doing the right thing. And you really see that here when he&#39;s just like, well, no, I really thought.</p>
                        <p>Bryan! (01:28:10.728)
                        It&#39;s local, yeah.</p>
                        <p>Mm-hmm.</p>
                        <p>Dave! (01:28:31.558)
                        Now he accepts like this is a real fucking problem because people are being eaten.</p>
                        <p>Bryan! (01:28:37.996)
                        Yeah, yeah, yeah. So Jaws is neatly broken up into two sections. This is a movie that runs all about like two, I think it&#39;s like two hours and three minutes or something like that. This is the one hour mark.</p>
                        <p>Dave! (01:28:48.214)
                        Yeah, we get into Act Two here, and Act Two was a very different movie.</p>
                        <p>Bryan! (01:28:51.978)
                        Yeah, it is. It&#39;s there&#39;s the part on the boat with Quinn, and there&#39;s everything that happens before it.</p>
                        <p>Dave! (01:28:56.35)
                        And I think I&#39;ll tell you, I think that the first half of this movie, we see some of Steven Spielberg&#39;s best instincts as a filmmaker. You see everything that he becomes very good at. You can see in all these domestic things that really make you really buy into the movie. In the second act of this movie, I think you see some of Steven Spielberg&#39;s worst instincts. He because he is fiercely nostalgic, he is a modern Ray Bradbury, essentially.</p>
                        <p>Bryan! (01:29:05.358)
                        Mm-hmm.</p>
                        <p>Really?</p>
                        <p>Bryan! (01:29:26.181)
                        Yeah, I can see that.</p>
                        <p>Dave! (01:29:26.546)
                        and he cannot resist going for the action adventure. Even when it&#39;s not called for and might detract from the overall experience, he cannot let it go and he takes it up too many times in this second half. And I think it sort of brings it down a little bit where it&#39;s like, now I feel like you&#39;re an excited big kid who gets to make his movie. And it&#39;s like, you felt like a more mature filmmaker the first half. Now you feel like you&#39;re giving us</p>
                        <p>Bryan! (01:29:30.836)
                        Mm-hmm. Oh, yeah.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:29:53.743)
                        Yeah.</p>
                        <p>Dave! (01:29:56.458)
                        Raiders of the Lost Ark.</p>
                        <p>Bryan! (01:29:57.88)
                        Oh, sure. Well, I mean, we got like a boat chases and the full view with a shark. We got guns. We got spear guns.</p>
                        <p>Dave! (01:30:00.99)
                        Yeah, and it feels a little much. Like if that had been a part of the story all along, it wouldn&#39;t feel so weird. It doesn&#39;t feel weird in Raiders of the Lost Ark because it&#39;s the whole fucking movie. It&#39;s not the whole movie of Jaws. Jaws is essentially a domestic drama for the first half with some pretty exciting moments. This second half is a very different movie. I think that&#39;s where it suffers a little bit.</p>
                        <p>Bryan! (01:30:21.948)
                        Yeah, I can see that.</p>
                        <p>Yeah, but this is where we finally get on the boat. Quint is a pain in the ass. He is in He antagonizes Hooper endlessly</p>
                        <p>Dave! (01:30:31.395)
                        Yeah, he fuckin&#39; sucks.</p>
                        <p>Apparently they do that. He did that to him off camera as well. Like he just fucking hated Richard Dreyfuss and went out of his way to fuck with him the whole time.</p>
                        <p>Bryan! (01:30:39.257)
                        Yeah, he was, he was.</p>
                        <p>Yeah, sounds miserable, but it makes sense that Milius has his fingertips on this dialogue. It&#39;s all salty tough guy shit.</p>
                        <p>Dave! (01:30:52.102)
                        Oh yeah, like how many fucking movies has John Milly saved? Prior to 1980.</p>
                        <p>Bryan! (01:30:57.038)
                        which is interesting because Milius was kind of a Los Angeles rich kid who imitates hard man individuals that he read about in like Steinbeck and Hemingway. But his movies definitely got that vibe and like a lot of stuff he wrote really kind of feels it. It feels pretty genuine.</p>
                        <p>Dave! (01:31:16.399)
                        He&#39;s like, I hung out with Raymond Chandler, did you?</p>
                        <p>Bryan! (01:31:19.384)
                        Yeah, yeah, guys got pretty dubious politics, but I personally</p>
                        <p>Dave! (01:31:23.05)
                        Oh yeah, I&#39;m sure just like every other fucking director from the 60s and 70s, he&#39;s probably a sack of shit.</p>
                        <p>Bryan! (01:31:28.744)
                        He&#39;s, yeah, he&#39;s pretty rough, but I love Conan the Barbarian, and I won&#39;t lie, I also like Red Dawn. It&#39;s, it&#39;s, me and Larry Clow both enjoy that movie a great deal. And yeah, and when we did, when we were doing, oh, Jesus Christ. So here&#39;s the thing, we handle it in a very ironic way, right?</p>
                        <p>Dave! (01:31:45.13)
                        What, Red Dawn? Speaking of propaganda...</p>
                        <p>Bryan! (01:31:57.336)
                        So when we were doing that Sub Rosa drive-in thing, we did a 4th of July screening of Red Dawn, and we let everybody know we&#39;re doing Red Dawn. It&#39;s gonna be awesome. And a lot of our friends came out, and the usual people came to see it, who also view it ironically. But there were, for every one of us, there were two people with Pork Fest and Gadsden flag.</p>
                        <p>you know, stickers on their cars and shit, who take it very seriously. And I had up to that point, I had no idea that contingent of Red Dawn fan existed. I should not be surprised, but I was.</p>
                        <p>Dave! (01:32:35.987)
                        No, you can be surprised because that is some cartoonish bullshit.</p>
                        <p>Bryan! (01:32:40.196)
                        It is and it&#39;s really, it was really funny because also this was the show that the cops broke up. This was the one when somebody ratted us out to swank motion pictures. So like it was a crazy, crazy night, but yeah.</p>
                        <p>Dave! (01:32:59.146)
                        I was in a punk rock band once and I wrote a song about Red Dawn. It&#39;s pretty great. Yeah, this is not about Red Dawn, and it never will be because this is not a good movie.</p>
                        <p>Bryan! (01:33:03.352)
                        you did yep anyways jaws no so quit does not shut up he&#39;s a huge asshole out of the water they&#39;re chumming the water dropping markers to try to lure the shark up quit has a deep like a deep sea real cast out</p>
                        <p>Dave! (01:33:24.31)
                        Yeah, let&#39;s not let&#39;s not miss Chikov&#39;s oxygen tank.</p>
                        <p>Bryan! (01:33:28.016)
                        That&#39;s right, yeah. And like that he&#39;s yelling at everybody to get all the shit tied down. But as this is happening, we can see the reel slowly reeling itself out. And he&#39;s noticed this. Like something&#39;s.</p>
                        <p>Dave! (01:33:41.446)
                        ask about this scene because Robert Shaw plays it real sneaky-like. My question is, does he think the shark will hear him?</p>
                        <p>Bryan! (01:33:46.001)
                        Yeah.</p>
                        <p>I mean, maybe, maybe because like, you never know like how they fucking sense sound and stuff and he starts to move around. Maybe it&#39;ll fucking know.</p>
                        <p>Dave! (01:33:59.122)
                        I think if you spent your entire life hunting sharks, you know that they cannot hear you. They did. I mean, they do have ears. They have little ears, but they&#39;re still not gonna hear you.</p>
                        <p>Bryan! (01:34:03.932)
                        Yeah. So he quietly like straps into his seat gets ready to reel it in and then suddenly what Evers on the on the line runs away with it. But then it gets away. It snaps the line, which apparently was piano wire. Yeah.</p>
                        <p>Dave! (01:34:24.238)
                        Because there is another scene that gets that is cut out of the movie where Robert Shaw is going to buy piano wire at a music store. And there&#39;s a kid in the music store like playing like fiddling around like a flute like playing a flute and quit like gets right up next to him just starts yelling in the kids here to fuck with him.</p>
                        <p>Bryan! (01:34:46.118)
                        Yeah, but yeah, so this scene kind of like subsides we get back to Brody chumming the waters again in one of the in another one of the movie&#39;s iconic scenes where as he throws the you know chumming the water</p>
                        <p>Dave! (01:34:58.126)
                        I&#39;ll give you slow ahead. Come on down here and chump some of this shit.</p>
                        <p>Bryan! (01:35:01.712)
                        Yep. So, uh, he, the shark suddenly surfaces, but he&#39;s like the only one to see it. And that&#39;s the scene where he shoots up really suddenly and utters the classic line. You&#39;re going to need a bigger boat. So everybody sees, suddenly sees the shark buzzing around the boat. Even Quinn, Quint comes to understand that this is way more than they were expecting. So he readies the spear gun. Um, yeah, there&#39;s, yeah.</p>
                        <p>Dave! (01:35:24.974)
                        That&#39;s right, this is his white whale. You say his what?</p>
                        <p>Bryan! (01:35:32.362)
                        Yeah, there&#39;s yeah there are blunt Moby Dick references all up and down this second half of the movie But they they&#39;re gonna they&#39;re gonna hit the shark with these spears that are tied to these like floating kegs so that they can draw it to the surface they can see where it&#39;s going</p>
                        <p>and it&#39;ll tire itself out trying to swim against the flotation. But what they don&#39;t expect is that the shark is able to swim against the flotation and it just disappears under this under the ocean with the with the barrel. So we cut to night and everybody&#39;s kind of drinking and sort of. Yeah. And this is where they do the thing that eventually like a lot of action movies adopt, where they compare scars.</p>
                        <p>Dave! (01:36:10.408)
                        And here it comes.</p>
                        <p>Bryan! (01:36:21.836)
                        and Quint and yeah Quint and Hooper sort of come to understand one another but Quint has like a faded tattoo on his arm that Hooper&#39;s kind of joking about this is when he gets real serious and we find out that Quint was on the USS Indianapolis when it went down so</p>
                        <p>Dave! (01:36:22.814)
                        Except they do this so much better.</p>
                        <p>Bryan! (01:36:49.268)
                        uh there&#39;s a pretty solid podcast about the Indianapolis that last podcast on the last on the left did a couple of years ago uh but in the short of it this is in the last days of world war ii as the U.S. Navy is basically taking more and more of the Pacific from the Japanese they&#39;re sailing closer to closer to Okinawa and the U.S.S Indianapolis was on a secret mission</p>
                        <p>It was not in a task force. It was a ship on its own with no escorts. And it was delivering one, I&#39;m not sure which one, but one of the two atomic bombs that get dropped on Japan. So the Fat Man, which I think was, I believe was the Hiroshima one. And, oh, he did, I did, because I know that he said that we delivered those bombs. Yeah. So.</p>
                        <p>Dave! (01:37:28.189)
                        The big one. Yes. Hiroshima. Because he says it later.</p>
                        <p>I&#39;ll get to that in a minute because it&#39;s part of my thing.</p>
                        <p>Bryan! (01:37:43.892)
                        um it was all by itself and it gets surprised by a Japanese submarine puts two put two torpedoes into the hull and it lists hard and it goes down very fast this is a ship that sunk in 12 minutes and 1100 men went overboard and essentially floated around in the water because this mission was so secret that nobody knew they were out there until about a week about like several days after they didn&#39;t they didn&#39;t arrive</p>
                        <p>So 1100. Well, I know those the specifics like where all the guys went in and. It&#39;s fucked up, they floated around in these little.</p>
                        <p>Dave! (01:38:12.703)
                        Do you know anything, do you know anything actually about this story, but what happened?</p>
                        <p>This is the most harrowing shit I have ever heard.</p>
                        <p>Because you can read the transcripts from the, there was a hearing that was held after. You can read those transcripts.</p>
                        <p>Bryan! (01:38:31.452)
                        Yes. Yeah, so this 1,100 guys floated around in very tight clusters, sort of clinging to life rafts, whatever floated. And then sharks discovered them and picked them off about six men an hour until they.</p>
                        <p>Dave! (01:38:49.346)
                        Because what sharks love is chaos. And what happened when this thing sank was chaos. It was blood and oil in the water everywhere, fire and loud noises and splashing and thrashing.</p>
                        <p>Bryan! (01:38:59.908)
                        Yep. So these guys had to endure this for like a week as bits and pieces of the of their like shipmates sort of surfaced. Quint has a story about it where like just the top half of one of his of one of his mates come. God, it&#39;s so bad. Yeah.</p>
                        <p>Dave! (01:39:17.522)
                        That is a real story.</p>
                        <p>where he just goes over and taps his friend and he upends and his bottom half is missing, because that would happen a lot. Now, what actually happened? The sharks didn&#39;t really take them all. Most of them drowned, because they were out there for so long that they became very thirsty and most of them would try to drink seawater. If you drink seawater, you&#39;re gonna die, but you&#39;ll also go crazy in the meantime. And so that&#39;s kind of what happened. And then they would come and take the dead bodies because what sharks...</p>
                        <p>Bryan! (01:39:35.331)
                        Yeah. Yeah, so. They don&#39;t want to work for it. Yeah, they want to conserve energy.</p>
                        <p>Dave! (01:39:48.65)
                        That&#39;s what they want. They don&#39;t want to have to work for things. So they will come. They&#39;re basically scavengers. They&#39;ll come and take the dead. They will take living people if they&#39;re dying, but they don&#39;t want to have to actually try to fight some.</p>
                        <p>Bryan! (01:40:02.685)
                        1100 men went in 316 came out. That&#39;s a lot of dead guys. Hmm.</p>
                        <p>Dave! (01:40:11.646)
                        And that&#39;s when he says, and he&#39;s kind of weirdly smiling as he tells the story, but in a very awkward, but like Robert Shaw, he may have been a piece of shit in his waking life, but he acts the shit out of this scene.</p>
                        <p>Bryan! (01:40:26.349)
                        Oh my God, yeah, this is the, this is the, the sharks got dead eyes like a doll&#39;s eyes like that. It&#39;s one of the big, the big moments. It&#39;s like a long single take shot where the camera just slowly zooms in on his face and kind of edges, drive us out of the scene. It&#39;s, it&#39;s, it&#39;s perfect. It&#39;s so good. Yeah.</p>
                        <p>Dave! (01:40:31.81)
                        DOWS EYES</p>
                        <p>And that&#39;s when he looks at Brody and says, anyway, we delivered the bomb. And again, it&#39;s that reminder of that greatest generation bullshit where it&#39;s like, sure, you&#39;re a cop and you might be, you know, a scientist or whatever the fuck he is. But we delivered the bomb. We did the thing. We ended the war. And of course, that is a reductive lie. But it also you also destroyed half a country and devastated an entire culture.</p>
                        <p>Bryan! (01:41:03.433)
                        Yeah.</p>
                        <p>Dave! (01:41:14.762)
                        We&#39;re gonna skip over that, because you did the heroic masculine thing. And that&#39;s, again, that&#39;s that idea of like, we ended it with brute force. That&#39;s how that story ends. The end, let&#39;s not focus on any of these specifics or the literal fallout of it.</p>
                        <p>Bryan! (01:41:35.847)
                        Yeah. Yep.</p>
                        <p>Bryan! (01:41:46.18)
                        So in the morning, the crew fix the boat and you can, and this, I love this because these are scenes that you can see that George Lucas also kind of, I don&#39;t know who came up with it, if Spielberg did or Lucas did, cause they were tight, but Lucas does this in Star Wars when like Chewbacca and Han Solo are fixing the boat, but all you see are like the open hatches and like, they&#39;re talking to each other and there&#39;s like arms coming up out of it and shit. Like you don&#39;t actually see them do it, but yeah.</p>
                        <p>Dave! (01:42:13.769)
                        I think this is where this is kind of where it goes a little off the rails. It&#39;s like it becomes a buddy comedy a little bit like the tone changes so hard that it&#39;s kind of like, Whoa, why are you making another movie?</p>
                        <p>Bryan! (01:42:23.29)
                        Yeah. But as they&#39;re doing this the barrel surfaces, they get ready to sort of deal with the shark again, they managed to catch up to it this time put more spears in it with more barrels.</p>
                        <p>So now they&#39;ve got like three barrels in it, which is like a lot. And so it still manages to swim under with three barrels attached to it. And that&#39;s when Quint stops with, yeah. And then it does. And that&#39;s when he&#39;s like, ah, fuck. So.</p>
                        <p>Dave! (01:42:51.926)
                        Not with three barrels, I can&#39;t.</p>
                        <p>Bryan! (01:43:00.884)
                        they get, they manage to catch the barrels because they&#39;re not moving around. It&#39;s not swimming, it&#39;s just kind of circling underneath. So they hook them, they tie them to the cleats at the back and at the stern of the ship. And the shark starts to swim away with the ship. And so...</p>
                        <p>Bryan! (01:43:21.064)
                        they it tears the cleats off the boat basically gets free but now it&#39;s like I don&#39;t know it&#39;s pissed off or something and starts to like chase them like they remark yeah so quint turns the ship to basically draw it into the shallows around amity and kind of run it out and drown it there he says but</p>
                        <p>Dave! (01:43:30.143)
                        I would be too, they&#39;ve just jammed this thing with eight different things, it&#39;s got two-three barrels tied to it.</p>
                        <p>But Ahab unfortunately has lost his mind.</p>
                        <p>Bryan! (01:43:49.27)
                        Yeah, so he we know that the ship is fucked but he He runs it too hard anyways, and he blows the engine And like starts a fire and shit. It&#39;s like as a whole problem</p>
                        <p>Dave! (01:44:00.526)
                        because he is intentionally trying to sabotage.</p>
                        <p>Bryan! (01:44:04.484)
                        Yeah. So now, engine cuts his fire, ships taken on water. And in a call back to his Indianapolis story, Quint vows to like never put a life jacket on again. His whole thing is like if I got to go back in the water, I&#39;m just gonna let the sea take me because fuck that. So he throws the two remaining jackets to Hooper and Brody and kind of readies himself. But now they set up the shark cage.</p>
                        <p>and it turns out that Hooper&#39;s got spears like this is the thing that earlier before Quint was like making fun of him because he does he&#39;s not fighting this thing with like conventional I don&#39;t know he&#39;s not gonna punch the shark in the face he&#39;s got this like big spear that&#39;s equipped with like uh strychnine that if he can if he can stick the shark with it it&#39;ll inject it very suddenly with this drug fucking kill it so they set the cage up they put kill they put Hooper</p>
                        <p>Dave! (01:44:42.4)
                        Yeah, he&#39;s not getting in there fisticuffs with this shark.</p>
                        <p>Bryan! (01:45:02.392)
                        into the cage so cage goes in the water. Yeah, shark&#39;s in water. No, shark. Salsa shark. And Hooper goes into the cage. He sees the shark coming for him immediately. It rams the cage and Hooper loses the dart. And so... Yep.</p>
                        <p>Dave! (01:45:05.774)
                        Sharks in the water, our shark, salsa shark.</p>
                        <p>But this scene is really incredible because this is like that moment where the shark kind of initially swims by a little bit, and then you see it disappear into the blackness, the murky depths, and then it comes back and like, you know, it&#39;s coming back and you probably expect it to come back in front of him and it doesn&#39;t. It comes back behind him.</p>
                        <p>Bryan! (01:45:32.444)
                        Yeah, into the Merc. Nope. Yep, it&#39;s a smart shark. That rams the cage, Hooper loses the dart, and then they struggle because the cage is trashed at this point. Like the shark has bent the bars, and it keeps coming at him. It keeps hitting him.</p>
                        <p>and breaks it open and Hooper in the chaos, like eventually the shark kind of gets twisted up in the cables above it. Hooper takes the opportunity to swim out of the cage and sort of like take cover at the bottom. He finds a place to hide. And when they do manage to bring, and it doesn&#39;t just like break the cage, it also like smashes the boom on the ship and like they have to rig a whole thing to bring it up. When they bring it up, they find the cage is just this empty fucked up thing.</p>
                        <p>And this is when the shark throws itself on the deck of the ship as we&#39;re heading into the sort of big climactic ending. The ship lists, the deck is wet, and Quint slips, sliding down the deck towards the shark&#39;s mouth. He struggles against it. Hahaha, yeah. It!</p>
                        <p>Dave! (01:46:42.566)
                        Wait, did you already say that fat bastard leaps out of the water and it really comes just lands in the stick? So I guess what were they gonna do? Were they gonna drag it back?</p>
                        <p>Bryan! (01:46:55.612)
                        They I okay, so it&#39;s not really clear what the plan was but he says we&#39;ll bring him back to we&#39;ll drag him back to the shadows and we&#39;ll drown him there. I don&#39;t know what that means I don&#39;t know how you drown a shark. Ah yeah, I know what&#39;s the thing is</p>
                        <p>Dave! (01:47:07.83)
                        I mean, again, was he the only guy? Like, there had to be somebody else, because this guy seems pretty unhinged from fucking Cracker 1. Like, there had to be somebody better.</p>
                        <p>Bryan! (01:47:22.208)
                        Hooper wants like the Coast Guard like the Coast Guard probably could have dealt with this but like Quinn is like fuck that Yeah, but also It&#39;s not it&#39;s not spoken. It&#39;s this is that kind of ham-fisted Ahab shit He&#39;s got a he&#39;s got a beef with sharks It turns out because like we also find him like one of the things we see him doing is like boiling shark jaws And like in a in a place in a like a dock</p>
                        <p>Dave! (01:47:28.334)
                        Yeah, it&#39;s eating little kids in fucking Martha&#39;s Vineyard.</p>
                        <p>Bryan! (01:47:49.736)
                        that&#39;s like a scene from like the Texas Chainsaw Massacre where there&#39;s like shark jaws and bones and shit like all over the place. So like he&#39;s killed a lot of sharks. He&#39;s obviously got it out for sharks because of his experience in the South Pacific. So like he&#39;s possessed. This is the thing that he&#39;s meant to do.</p>
                        <p>Dave! (01:48:07.921)
                        What did I say at the beginning? The shark will possess you.</p>
                        <p>Bryan! (01:48:10.928)
                        Yep, sharks are cyst. So, yeah, so Quint slides down, he struggles against it, falls into its mouth and is eaten. It&#39;s the best. Yeah, and even like, I remember the very first time I ever saw this, like I was like, I don&#39;t know, I was probably nine or something, and they ran it on TV and they ran it, it was on one of like the local UHF stations. So they didn&#39;t cut shit for it.</p>
                        <p>Dave! (01:48:18.622)
                        and a fucking gnarly scene, which this... They had to cut this in order to get a PG rating.</p>
                        <p>Bryan! (01:48:39.448)
                        And he like the gout of blood that sort of erupts from this is No He does that big yeah</p>
                        <p>Dave! (01:48:43.586)
                        Have you ever seen the original version before they cut it down? Oh, so the reason they had to be so they cut it so they would not get an R rating because they didn&#39;t want an R rating because initially when he spits up blood because he&#39;s the shark chomps onto him and he sort of spits blood, it kind of like flies off to the side. Initially, when he did it, this fucking like again, a geyser of blood like covers his whole face.</p>
                        <p>Bryan! (01:49:09.961)
                        Oh man. That&#39;s awesome. Yeah. So uh yeah so eventually the shark drags him away and finishes him. You would think but he comes back.</p>
                        <p>Dave! (01:49:10.59)
                        It&#39;s like in his eyes, it&#39;s his whole face is just a fucking sheet of blood. That&#39;s it&#39;s on the fucking 4K release that Universal did.</p>
                        <p>Presumably satisfied.</p>
                        <p>Bryan! (01:49:35.108)
                        as the boat is sinking and Brody fights it off with like the spare air tank that Hooper left. And ultimately he jams it into the shark&#39;s mouth and it swims away. So we see</p>
                        <p>Dave! (01:49:48.302)
                        Again, this thing spits out a head, it&#39;s spitting out a leg, why doesn&#39;t it just spit out a fucking oxygen tank?</p>
                        <p>Bryan! (01:49:55.14)
                        It&#39;s a killing machine, man. It&#39;s it maybe it likes it. I don&#39;t know. I mean, it&#39;s convenience for the plot, but well, we see the fan off in the distance. Brody climbs to the helm and he grabs the rifle and then he climbs to the crow&#39;s nest as the boat continues to sink.</p>
                        <p>That awesome John Williams score is just fucking throbbing the whole time. Like it&#39;s, this is a really, really great like climactic scene. Waits for the shark to surface with the ready, with the rifle ready to go. Fires a bunch at it. And then it surfaces enough for him to see the tank. He shoots the tank and blows the shark to pieces. It&#39;s a smile. You son of a bitch. Yep.</p>
                        <p>Dave! (01:50:33.398)
                        I&#39;m just gonna skip the line, huh? Smile, you son of a bitch. Now here is my problem with the ending. And the shark explodes, and the end. My problem with the ending is that we spend half of this movie being told you cannot brute force your way out of this problem. You cannot drink Clorox your way out of the fucking COVID pandemic. And then in the end, it&#39;s exactly what he does.</p>
                        <p>Bryan! (01:50:52.052)
                        I&#39;m gonna prove any proof courses this way out of the problem yeah yep yeah</p>
                        <p>Dave! (01:51:01.634)
                        So the whole time it&#39;s like, no, because Hooper is right every single fucking time. He&#39;s like, everything he does, he&#39;s like, no, what I said is true, now you see that. So we&#39;re getting some real mixed messages here. And so it begs the question, is it just, it has to be the right person to do it? It can&#39;t be the aging guy, it can&#39;t be the single nerd, it&#39;s gotta be the heteronormative, middle of the road guy with a family.</p>
                        <p>Bryan! (01:51:13.057)
                        Well, here&#39;s the thing. Ah, here&#39;s the thing. I. I think you&#39;re overthinking it. This is the big loud.</p>
                        <p>Dave! (01:51:32.71)
                        I don&#39;t think I&#39;m overthinking it. The whole fucking movie, they&#39;re telling us, you can&#39;t, that&#39;s not how you&#39;re gonna solve this problem. You can&#39;t wish it into the cornfield. You cannot just shoot it in the face. You have to have a better strategy. It cannot be this old school way. And then in the end, it&#39;s the old school way.</p>
                        <p>Bryan! (01:51:38.684)
                        Sure, but... But if they... But if it were anything else, if they had gone through protocol, if they had brought the Coast Guard in, if they had hit it with the spear, with the drugs in it, it would have been way less exciting than this fucking showdown. Like, you gotta admit, it&#39;s a fucking awesome ending!</p>
                        <p>Dave! (01:52:06.402)
                        but it still undermines, it is, but it undermines its entire fucking premise. The whole point of this movie is, if we&#39;re struggling with this idea of what is this new man who has to solve this problem. And I think, and I don&#39;t even think that&#39;s a postmodern take. I think that is literally what this thing is about. And in the very end, it&#39;s just like, oh, so, but now you&#39;re just gonna revert back to it, I guess. Like it&#39;s, hey, don&#39;t worry about it. It&#39;s just masculinity in a different form. And it&#39;s like, okay, I guess.</p>
                        <p>Bryan! (01:52:17.364)
                        I guess. Yeah.</p>
                        <p>Dave! (01:52:35.47)
                        but you sort of undermine your whole fucking story.</p>
                        <p>And then, but you do get another great moment where again, Hooper pops up from under the water and it&#39;s like, he&#39;s arrived, hooray, hooray for healthy male friendship.</p>
                        <p>Bryan! (01:52:47.76)
                        Yeah, I. Yep. In the book, Hooper dies, he pays for his transgressions against Brody.</p>
                        <p>Dave! (01:52:55.39)
                        Everybody&#39;s got fucking transgressions in the book. I don&#39;t know. I feel like he makes this really incredible, a nuanced, complex movie, and then he cannot resist the urge to turn it into a fucking swash buckling adventure. And I love this movie from beginning to end. I think it is an incredible film. But I think he really does himself a disservice with the second half where it&#39;s like you made this told us great story. It&#39;s very complicated. It&#39;s all about people.</p>
                        <p>And it&#39;s a nuanced look at what life in this sort of small island town is. And then you just sort of fuck off with the rest of it. Like, who cares about that first half? The first half could never have happened, and it wouldn&#39;t change the second half. And it&#39;s kind of like that&#39;s where it&#39;s not a cohesive story. It&#39;s a fun story. It&#39;s great from beginning to end. But he undermines the first half completely. Like, why bother with any of that other than it&#39;s just great filmmaking? But</p>
                        <p>Bryan! (01:53:43.327)
                        I</p>
                        <p>Dave! (01:53:52.31)
                        You&#39;ve made two different movies and just sort of smashed them together.</p>
                        <p>Bryan! (01:53:55.379)
                        This is true. I just I don&#39;t know how you sort of respect the first half and end it with a big sort of like exciting climax.</p>
                        <p>Dave! (01:54:07.754)
                        Well, I think it does. There is something interesting that does happen at the end, and it involves the score more than anything else. When he does blow this shark up, and the second half, again, one of the reasons I don&#39;t love the score is that the second half is again, that big adventurous swashbuckling bullshit, which is kind of like, oh, it&#39;s a little over the top. The second half is just kind of hammy in general, but when the shark explodes, you get this very kind of mournful moment that is not so,</p>
                        <p>Bryan! (01:54:24.413)
                        Mm.</p>
                        <p>Dave! (01:54:37.678)
                        There is not a kind of big orchestral swell. It&#39;s this really thoughtful kind of like wistful music as the, you really kind of just see like a bloom of blood in the water and pieces of the shark kind of fall away to the bottom. And it is a kind of like thoughtful moment of like, it&#39;s over, what did we do? What happened? What is this thing?</p>
                        <p>Bryan! (01:55:02.724)
                        Yeah, yeah, yeah. You know what it kind of makes me think of is the end of King Kong. You know, where it&#39;s like, oh, we won, but we killed this amazing thing in the process. You know?</p>
                        <p>Dave! (01:55:11.405)
                        Yeah.</p>
                        <p>Right. And it&#39;s sort of it&#39;s the kind of a bit of a saving grace of that moment of like, well, it&#39;s over. But what was it? And what am I now? And what is all this? Like, I think without that moment, it would feel a lot more bullshitting.</p>
                        <p>Bryan! (01:55:40.392)
                        Hmm. Yeah.</p>
                        <p>Dave! (01:55:42.218)
                        So there is that kind of saving grace. But again, I think that the second half of this movie, as much as I like it, it does sort of undermine the entire.</p>
                        <p>Bryan! (01:55:49.628)
                        Sure, sure, I&#39;ll give you that. So yeah, they paddle back to shore together. Roll credits. If they were out in the open, if they were out in the open,</p>
                        <p>Dave! (01:55:57.738)
                        And thank God you can see part of the shore, because for most of my life, I was just like, well, how are they gonna get back? Where are they? You&#39;re just like out in the Atlantic. Like it&#39;s gonna take you days to get back.</p>
                        <p>Bryan! (01:56:05.336)
                        If they were out in the open, in like the open ocean, I&#39;d be like, oh my God. Cause there&#39;s, cause he, there&#39;s, we were just watching it earlier, but there is a scene right at the beginning of the last crusade where Jones gets to cross a Coronado back and then he leaps off of this ship in the middle of a fucking storm in God knows where in the ocean and the ship blows up and it&#39;s like, what the fuck did he get back home?</p>
                        <p>And that stuff makes me very nervous. I don&#39;t know why. Something about the ocean, the isolation of it, really freaks me out.</p>
                        <p>Dave! (01:56:42.736)
                        But yeah, but thank God you can see like a you can see a little bit of land off to the left and it&#39;s like so they&#39;re not that far away, but they&#39;re still pretty far away.</p>
                        <p>Bryan! (01:56:48.209)
                        Yeah.</p>
                        <p>Yeah, yeah, but yeah, Jaws, man. Fucking Jaws, goddamn, man. Like, and just like in other movies, just like what we did in the old Dark House, I start out really, really loving this movie and just talking about it like this makes me appreciate it even more. Like just digging up.</p>
                        <p>Dave! (01:56:56.002)
                        That&#39;s Jaws.</p>
                        <p>because he makes he makes so many right choices in this. Like everything, his instincts are so good in almost everything he does. I may not like a lot of the stuff that happens in the second half, but I still think they&#39;re good. You know, he has good instincts as a director better than anybody else because he just it&#39;s feels it all feels very natural. He&#39;s not trying to convince you of anything. He just wants you to love what he loves.</p>
                        <p>Bryan! (01:57:39.504)
                        Yeah. Yep. Steven Spielberg. I know. I&#39;m a huge fan of the guy. I used to be... I went to school for visual effects. And I was sort of adjacent to a lot of film students who wouldn&#39;t shut up about this guy. I&#39;ll be the next Steven Spielberg. It&#39;s like, well, none of them did, but...</p>
                        <p>Dave! (01:57:44.394)
                        And I don&#39;t, I&#39;m not even a big fan. I mean, I say that, but I kind of am, I guess. I mean.</p>
                        <p>Bryan! (01:58:11.35)
                        um there&#39;s something about him that was like he was such a force in the 1980s he had he touched so much of the pop culture that we sort of consumed and grew up on that after a while like i just don&#39;t want to fucking hear about the guy like i get it he&#39;s awesome but i start to get kind of smug about it but now like as i&#39;m an adult and those little stakes are so much fucking lower i don&#39;t</p>
                        <p>Like I really kind of appreciate it a little bit more. I still, I don&#39;t really care for the kind of dramatic turn that he took in the nineties. I really, I love the kind of adventure, exciting shit that he did in the eighties. I have not seen the fabelmans. I don&#39;t give a fuck about the fabelmans. I don&#39;t wanna, you know, I don&#39;t wanna deal with his pathology, but he&#39;s definitely, he&#39;s one of the all time greats. And it&#39;s definitely, it&#39;s an earned reputation. Like I, I...</p>
                        <p>Dave! (01:59:02.151)
                        And I, well, I think he, like I said, he is so genuine in his love of this that you cannot help but love what he does.</p>
                        <p>Bryan! (01:59:07.794)
                        Yeah.</p>
                        <p>There&#39;s a charisma to it that, you know, his contemporaries didn&#39;t have. Like George Lucas does not have this.</p>
                        <p>Dave! (01:59:15.87)
                        And it also, it just happens that he&#39;s also incredibly innovative.</p>
                        <p>Bryan! (01:59:19.632)
                        Yeah. Yeah, like him and what Lucas was doing at Industrial Light and Magic, like that one-two punch set the pace for everything else that came afterwards.</p>
                        <p>Dave! (01:59:31.434)
                        Yeah, George Lucas doesn&#39;t have what he doesn&#39;t have what it takes to make the movie come off. Yeah.</p>
                        <p>Bryan! (01:59:35.664)
                        No, but the technical shit. Perfect. Yeah, 100%.</p>
                        <p>Dave! (01:59:40.374)
                        And that&#39;s why, well, that&#39;s why Indiana Jones works so well. It&#39;s like you get both of them. And now Spielberg can do it without George Lucas. I don&#39;t think George Lucas can do it without Spielberg.</p>
                        <p>Bryan! (01:59:43.376)
                        Yeah.</p>
                        <p>No, no, I think he needs that like prod, that push to be like, no, you got to bring the story back here. It&#39;s also it&#39;s kind of the reason why like Star Wars is great. I enjoy it a great deal, but that whole thing doesn&#39;t really take off for me until the Empire Strikes Back. And that&#39;s just George Lucas as a producer and like a bit of a writer. But that was Irving Kirchner&#39;s movie, you know, like when he&#39;s not involved, he&#39;s doing like that&#39;s when the best George Lucas stuff happens. But yeah.</p>
                        <p>Steven Spielberg, the shit, Jaws, fucking awesome. You know, it&#39;s such a great movie and I hope everybody watches it tomorrow. But yeah, get eaten by a shark. Gouts of blood, watch a child die. So yeah, we&#39;ll be back on Monday with our regularly scheduled episode when we take you to camp with Madman. So we&#39;ll see you then. And a guy named TP.</p>
                        <p>Dave! (02:00:28.003)
                        Yeah, Fourth of July, get eaten by a shark.</p>
                        <p>No sharks, but lots of people die.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/madman">Next Episode</Link></li>
                        <li><Link to="/episodes/the-old-dark-house">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)